import React, { createContext, useEffect, useState, useMemo } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { message } from 'antd';
import Axios from 'axios';
import Verify from './pages/doctors/Verify';
import { SERVER_URL } from './config';
import Layout from './components/base/Layout';
import Login from './pages/Login';
import './App.scss';
import axios from 'axios';

export const UserContext = createContext(null);
export const NotificationContext = createContext(null);

const handleLogin = async (user) => {
  try {
    const userResponse = await Axios.post(
      `${SERVER_URL}/magistrala/login`,
      { email: user.email, password: user.password },
      { withCredentials: false },
    );
    if (userResponse.data.message === 'Auth successful') {
      sessionStorage.setItem('user', JSON.stringify(userResponse.data));
      window.location.reload();
    }
  } catch (err) {
    console.log('\n Login error = ', err.response.data.message);
    return message.warning(err.response.data.message, 3);
  }
};

const App = () => {
  const [unreadNotification, setUnreadNotification] = useState(0);
  const [isLoggedIn, setLoggedIn] = useState(true);
  const [language, setLanguage] = useState();
  let user = sessionStorage.getItem('user');
  if (user) user = JSON.parse(user);

  const headers = {
    withCredentials: false,
    headers: { Authorization: `Bearer ${user?.token}` },
  };

  const getNumberOfNotifcations = async () => {
    try {
      if (user && user.id) {
        const { data } = await axios.get(`${SERVER_URL}/notifications/unread/${user.id}`, headers);
        setUnreadNotification(data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const getAllDoctorNotifications = async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/notifications/${user.id}`, headers);
      return data;
    } catch (error) {
      console.log(error.message);
    }
  };

  const sidebarContext = useMemo(
    () => ({
      getNumberOfNotifcations: async () => {
        await getNumberOfNotifcations();
      },
    }),
    [],
  );

  useEffect(() => {
    getNumberOfNotifcations();
  }, []);

  useEffect(() => {
    if (user && new Date() > new Date(user.expires)) {
      console.log('Session expired!');
      setLoggedIn(false);
    } else if (user === null) {
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
    }
  }, [setLoggedIn, user]);

  useEffect(() => {
    Axios.get(`${SERVER_URL}/languages-selected`)
      .then((resp) => {
        const defaultLanguage = resp.data.find((l) => l.default);
        setLanguage({ selected: defaultLanguage, list: resp.data, default: defaultLanguage });
      })
      .catch((err) => console.log(err.response ? err.response.data.message : err.message));
  }, [setLanguage]);

  return (
    <NotificationContext.Provider value={{ sidebarContext, unreadNotification, getAllDoctorNotifications }}>
      <UserContext.Provider value={{ isLoggedIn, handleLogin, data: user, language, setLanguage }}>
        <Router>
          {isLoggedIn && <Layout />}
          {!isLoggedIn && <Route exact path='/' component={Login} />}
          {!isLoggedIn && <Route exact path='/magistrala' component={Login} />}
          {!isLoggedIn && <Route exact path='/login' component={Login} />}
          <Route exact path='/email-verification/:tokenId' component={Verify} />
        </Router>
      </UserContext.Provider>
    </NotificationContext.Provider>
  );
};

export default App;
