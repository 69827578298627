import React, { useState, useEffect, useContext } from 'react';
import { Form, Card, Divider, Select, Input, notification } from 'antd';
import { DOCTOR_URL, SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/sr';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { useHistory } from 'react-router-dom';

moment.locale('sr');

const { Option } = Select;

const NewDoctor = (props) => {
  const history = useHistory();
  const { doctorId } = props.match.params;
  const [form] = Form.useForm();
  const user = useContext(UserContext);
  const [doctor, setDoctor] = useState();
  const [cities, setCities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [ordinations, setOrdinations] = useState([]);
  const [phone, setPhone] = useState();

  const headers = {
    withCredentials: false,
    headers: {
      Authorization: `Bearer ${user.data.token}`,
    },
  };

  const getDoctor = async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/doctor/${doctorId}`, headers);
      setDoctor(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (doctorId) {
      getDoctor();
    }
  }, [doctorId]);

  if (doctor?.firstName) {
    form.setFieldsValue({
      ...doctor,
      phone: doctor.phone[0],
      ordination: doctor.ordination.map((ord) => ord._id),
    });
  }

  const getCities = async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/cities`, headers);
      setCities(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getData = async () => {
    try {
      // const { data } = await axios.get(`${DOCTOR_URL}/melo/additional`, headers);
      const galenaOrdination = await axios.get(`${SERVER_URL}/ordinations`, headers);
      // console.log(galenaOrdination);
      // setCategories(data.categories);
      setCategories([
        'Alergologija',
        'Dermatologija',
        'Dermatovenerologija',
        'Endokrinologija',
        'Epidemiologija',
        'Estetska hirurgija',
        'Estetska medicina',
        'Fizikalna medicina i rehabilitacija',
        'Gastroenterologija',
        'Ginekologija',
        'Hematologija',
        'Hiperbrična i podvodna medicina (Baromedicina)',
        'Hirurgija',
        'Interna medicina',
        'Kardiologija',
        'Kućno lečenje i nega',
        'Kvantna medicina',
        'Laboratorija',
        'Laboratorija - Biohemija',
        'Laboratorija - Mikrobiologija',
        'Laboratorija - Patohistanološka',
        'Medicina rada',
        'Nefrologija',
        'Neonnatologija',
        'Neurologija',
        'Nuropsihijatrija',
        'Neuropsihijatrija',
        'Nutricionizam',
        'Oftamologija',
        'Onkologija',
        'Opšta medicina',
        'Oralna Hirurgija',
        'Ortodoncija',
        'Ortopedija',
        'Otorinolaringologija (uho - grlo - nos)',
        'Pedijatrija',
        'Plastična i rekonstruktivna hirurgija',
        'Psihijatrija',
        'Psihologija',
        'Pulmologija',
        'Radiologija',
        'Reumatologija',
        'Stomatologija',
        'Transfuziologija',
        'Ultrazvučna dijagnostika',
        'Urologija',
        'Vaskularna hirurgija',
      ]);

      setOrdinations(galenaOrdination.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getData();
    getCities();
  }, []);

  const onFinish = async (values) => {
    try {
      if (!doctorId) {
        const { data } = await axios.post(`${SERVER_URL}/doctors`, values, headers);
        notification.success({
          message: data.message,
          placement: 'bottomRight',
        });
      } else {
        const { data } = await axios.put(`${SERVER_URL}/doctor/${doctorId}`, values, headers);
        notification.success({
          message: data.message,
          placement: 'bottomRight',
        });
      }
      setTimeout(() => history.push('/magistrala/doctors'), 800);
    } catch (error) {
      notification.warn({
        message: error.response.data.message,
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='dashboard'>
      <div className='card-wrapper' style={{ justifyContent: 'center' }}>
        <Card title={!doctorId ? 'Nov profil doktora' : 'Azuriraj doktora'} bordered={false}>
          <Form className='form-horizontal' form={form} onFinish={(values) => onFinish(values)} layout='vertical'>
            <Form.Item
              label='IME'
              name='firstName'
              rules={[
                {
                  required: true,
                  message: 'Molimo Vas, unesite ime!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label='PREZIME'
              name='lastName'
              rules={[
                {
                  required: true,
                  message: 'Molimo, unesite prezime!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label='EMAIL'
              name='email'
              rules={[
                {
                  required: true,
                  message: 'Molimo, unesite ispravan email!',
                },
              ]}
            >
              <Input type='email' />
            </Form.Item>

            {!doctorId && (
              <Form.Item
                label='LOZNIKA'
                name='password'
                rules={[
                  {
                    required: true,
                    message: 'Molimo, unesite lozinku!',
                  },
                ]}
              >
                <Input type='password' />
              </Form.Item>
            )}

            <Form.Item label='BROJ LICENCE' name='license'>
              <Input />
            </Form.Item>

            <Form.Item
              label='GRAD'
              name='city'
              rules={[
                {
                  required: true,
                  message: 'Molimo, unesite grad!',
                },
              ]}
            >
              <Select mode='multiple' showArrow={true}>
                {cities?.map((city) => (
                  <Option key={city._id} value={city.title}>
                    {city.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label='TELEFON'
              name='phone'
              rules={[
                {
                  required: true,
                  message: 'Molimo, unesite broj telefona!',
                },
              ]}
            >
              <PhoneInput
                style={{ border: '1px solid #d9d9d9', borderRadius: '2px', padding: '4px 11px' }}
                international
                countryCallingCodeEditable={false}
                defaultCountry='RS'
                value={phone}
                onChange={setPhone}
              />
            </Form.Item>

            <Form.Item label='ZDRASTVENA USTANOVA' name='ordination'>
              <Select
                showArrow
                showSearch
                mode='multiple'
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {ordinations?.map((ord) => (
                  <Option key={ord._id} value={ord._id}>
                    {ord.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label='SPECIJALIZACIJA'
              name='category'
              rules={[{ required: true, message: 'Molimo, unesite specijalizaciju!' }]}
            >
              <Select
                showArrow
                showSearch
                mode='multiple'
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {categories?.map((cat) => (
                  <Option key={cat} value={cat}>
                    {cat}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Divider type='horizontal' />
            <div className='text-right'>
              <button className='b2b-primary' htmlType='submit'>
                <span>{!doctorId ? 'Kreiraj' : 'Azuriraj'}</span>
              </button>
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default NewDoctor;
