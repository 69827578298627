import React, { useState, useEffect, useContext } from 'react';
import { Form, Card, Divider, Select, Input, notification } from 'antd';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import axios from 'axios';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { useHistory } from 'react-router-dom';

const { Option } = Select;

const NewPatient = (props) => {
  const history = useHistory();
  const { patientId } = props.match.params;
  const [form] = Form.useForm();
  const user = useContext(UserContext);
  const [patient, setPatient] = useState();
  const [cities, setCities] = useState();
  const [phone, setPhone] = useState();

  const headers = {
    withCredentials: false,
    headers: {
      Authorization: `Bearer ${user.data.token}`,
    },
  };

  const getPatient = async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/patient/${patientId}`, headers);
      setPatient(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (patientId) {
      getPatient();
    }
  }, [patientId]);

  if (patient?.firstName) {
    patient.ordinations = patient.ordinations?.filter((ord) => ord.status === 'ACTIVE');
    patient.doctors = patient.doctors?.filter((doc) => doc.status === 'ACTIVE');
    form.setFieldsValue({
      ...patient,
      phone: patient.phone[0],
      ordinations: patient.ordinations?.map((ord) => ord._id),
      doctors: patient.doctors.map((doc) => doc._id),
    });
  }

  const getCities = async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/cities`, headers);
      setCities(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getCities();
  }, []);

  const onFinish = async (values) => {
    values.phone = phone;

    try {
      if (!patientId) {
        const { data } = await axios.post(`${SERVER_URL}/patients`, values, headers);
        notification.success({
          message: data.message,
          placement: 'bottomRight',
        });
      } else {
        const { data } = await axios.put(`${SERVER_URL}/patient/${patientId}`, values, headers);
        notification.success({
          message: data.message,
          placement: 'bottomRight',
        });
      }
      setTimeout(() => history.push('/magistrala/patients'), 800);
    } catch (error) {
      notification.warn({
        message: error.response.data.message,
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='dashboard'>
      <div className='card-wrapper' style={{ justifyContent: 'center' }}>
        <Card title={!patientId ? 'Nov profil pacijenta' : 'Azuriraj pacijenta'} bordered={false}>
          <Form form={form} className='form-horizontal' onFinish={(values) => onFinish(values)} layout='vertical'>
            <Form.Item
              label='IME'
              name='firstName'
              rules={[
                {
                  required: true,
                  message: 'Molimo Vas, unesite ime!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='PREZIME'
              name='lastName'
              rules={[
                {
                  required: true,
                  message: 'Molimo, unesite prezime!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label='DATUM ROĐENJA'
              name='dateOfBirth'
              rules={[
                {
                  required: true,
                  message: 'Molimo, unesite datum rođenja!',
                },
              ]}
            >
              <Input type='date' />
            </Form.Item>

            <Form.Item
              label='EMAIL'
              name='email'
              rules={[
                {
                  required: true,
                  message: 'Molimo, unesite email!',
                },
              ]}
            >
              <Input type='email' />
            </Form.Item>
            <Form.Item label='TELEFON' name='phone'>
              <PhoneInput
                style={{ border: '1px solid #d9d9d9', borderRadius: '2px', padding: '4px 11px' }}
                international
                defaultCountry='RS'
                countryCallingCodeEditable={false}
                value={phone}
                onChange={setPhone}
              />
            </Form.Item>

            <Form.Item label='GRAD' name='city'>
              <Select
                showArrow={true}
                showSearch
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {cities?.map((city) => (
                  <Option key={city.title} value={city.title}>
                    {city.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Divider type='horizontal' />
            <div className='text-right'>
              <button className='b2b-primary' htmlType='submit'>
                <span>{!patientId ? 'Kreiraj' : 'Azuriraj'}</span>
              </button>
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default NewPatient;
