import ViewUser from '../pages/users/ViewUser';
import Doctors from '../pages/doctors/Doctors';
import NewDoctor from '../pages/doctors/NewDoctor';
import Patients from '../pages/patients/Patients';
import NewPatient from '../pages/patients/NewPatient';
import Recepies from '../pages/recepies/Recepies';
import NewRecepie from '../pages/recepies/NewRecepie';
import Ordinations from '../pages/ordinations/Ordinations';
import NewOrdination from '../pages/ordinations/NewOrdination';
import Accounts from '../pages/accounts/Accounts';
import NewAccount from '../pages/accounts/NewAccount';
import Notifications from '../pages/notifications/Notifications';
import PatternGroups from '../pages/pattern-groups/PatternGroups';
import Patterns from '../pages/patterns/Patterns';
import EditPatternGroup from '../pages/pattern-groups/EditPatternGroup';
import EditPattern from '../pages/patterns/EditPattern';

const routes = [
  {
    label: 'Doktori',
    showInMenu: ['superAdmin'],
    children: [
      {
        label: 'Svi doktori',
        path: '/magistrala/doctors',
        component: Doctors,
        allowed: ['superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Pregled Korisnika',
        path: '/magistrala/view-user/:id',
        component: ViewUser,
        allowed: ['superAdmin', 'admin', 'doctor'],
        showInMenu: false,
      },
      {
        label: 'Kreiraj doktora',
        path: '/magistrala/doctor/new',
        component: NewDoctor,
        allowed: ['superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Izmena doktora',
        path: '/magistrala/doctor/:doctorId',
        component: NewDoctor,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Pacijenti',
    showInMenu: ['superAdmin', 'doctor', 'admin'],
    children: [
      {
        label: 'Svi pacijenti',
        path: '/magistrala/patients',
        component: Patients,
        allowed: ['superAdmin', 'doctor', 'admin'],
        showInMenu: true,
      },
      {
        label: 'Kreiraj pacijenta',
        path: '/magistrala/patient/new',
        component: NewPatient,
        allowed: ['superAdmin', 'doctor', 'admin'],
        showInMenu: true,
      },
      {
        label: 'Izmena pacijenta',
        path: '/magistrala/patient/:patientId',
        component: NewPatient,
        allowed: ['superAdmin', 'doctor', 'admin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Zdravstvene ustanove',
    showInMenu: ['superAdmin', 'doctor', 'admin'],
    children: [
      {
        label: 'Sve ustanove',
        path: '/magistrala/ordinations',
        component: Ordinations,
        allowed: ['superAdmin', 'doctor', 'admin'],
        showInMenu: true,
      },
      {
        label: 'Kreiraj ustanovu',
        path: '/magistrala/ordination/new',
        component: NewOrdination,
        allowed: ['superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Izmena ustanove',
        path: '/magistrala/ordination/:ordinationId',
        component: NewOrdination,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Obrasci lekova',
    showInMenu: ['superAdmin'],
    children: [
      {
        label: 'Sve grupe obrazaca',
        path: '/magistrala/pattern-groups',
        component: PatternGroups,
        allowed: ['superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Svi obrasci',
        path: '/magistrala/patterns',
        component: Patterns,
        allowed: ['superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Kreiraj grupu obrazaca',
        path: '/magistrala/new-pattern-group',
        component: EditPatternGroup,
        allowed: ['superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Kreiraj obrazac',
        path: '/magistrala/new-pattern',
        component: EditPattern,
        allowed: ['superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Izmeni obrazac',
        path: '/magistrala/edit-pattern/:patternId',
        component: EditPattern,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Izmeni grupu obrazaca',
        path: '/magistrala/edit-pattern-group/:patterngroupId',
        component: EditPatternGroup,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Recepti',
    showInMenu: ['superAdmin', 'doctor', 'admin'],
    children: [
      {
        label: 'Svi recepti',
        path: '/magistrala/recepies',
        component: Recepies,
        allowed: ['doctor', 'superAdmin', 'admin'],
        showInMenu: true,
      },
      {
        label: 'Kreiraj recept',
        path: '/magistrala/recepie/new',
        component: NewRecepie,
        allowed: ['superAdmin', 'doctor', 'admin'],
        showInMenu: true,
      },
      {
        label: 'Izmeni recept',
        path: '/magistrala/recepie/:recepieId',
        component: NewRecepie,
        allowed: ['superAdmin', 'doctor', 'admin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Radni nalozi',
    showInMenu: ['superAdmin'],
    children: [
      {
        label: 'Svi radni nalozi',
        path: '/magistrala/accounts',
        component: Accounts,
        allowed: ['superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Izmeni radni nalog',
        path: '/magistrala/account/:accountId',
        component: NewAccount,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
    ],
  },
  // {
  //   label: 'Notifikacije',
  //   showInMenu: ['doctor', 'admin'],
  //   children: [
  //     {
  //       label: 'Sve notifikacije',
  //       path: '/magistrala/notifications',
  //       component: Notifications,
  //       allowed: ['doctor', 'admin'],
  //       showInMenu: true,
  //     },
  //   ],
  // },
];

export default routes;
