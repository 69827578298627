import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { io } from 'socket.io-client';
import { SERVER_URL, SOCKET_URL } from '../../config';
import { notification, Badge } from 'antd';
import { NotificationContext, UserContext } from '../../App';
import NotificationTable from '../../components/tables/NotificationTable';
import axios from 'axios';

const Notifications = () => {
  const history = useHistory();
  // const socket = io(SOCKET_URL, { path: '/socket.io' });
  const [allNotifications, setAllNotifications] = useState();
  let { sidebarContext, getAllDoctorNotifications } = useContext(NotificationContext);
  const currentuser = useContext(UserContext);

  const headers = {
    withCredentials: false,
    headers: { Authorization: `Bearer ${currentuser.data.token}` },
  };

  const callOnce = async () => {
    const data = await getAllDoctorNotifications();
    setAllNotifications(data);
  };

  useEffect(() => {
    callOnce();
  }, []);

  const readNotifications = async () => {
    try {
      await axios.put(`${SERVER_URL}/notifications/${currentuser.data.id}`, null, headers);
      await sidebarContext.getNumberOfNotifcations();
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (history.location.pathname === '/magistrala/notifications') {
      readNotifications();
    }
  });

  // useEffect(() => {
  //   socket.emit('connected', currentuser?.data?.id);
  //   socket.on('send-notification', async (data) => {
  //     const notifications = await getAllDoctorNotifications();
  //     setAllNotifications(notifications);
  //     notification.info({
  //       message: data.message,
  //       placement: 'bottomRight',
  //     });
  //     await sidebarContext.getNumberOfNotifcations();
  //   });
  // }, [socket]);

  const columnKeys = ['message', 'ordination', 'description', 'date', 'status'];
  const columnKeysTranslated = ['poruka', 'ordinacija', 'opis', 'datum', 'status'];

  const data = allNotifications?.map((notification) => ({
    ...notification,
    message: `Recept pod brojem ${notification.recepie.workOrderNumber} je azuriran`,
    date: new Date(notification.createdAt).toString().substring(0, 21),
    status: (
      <Badge status={notification.read ? 'success' : 'error'} text={notification.read ? 'Procitan' : 'Neprocitan'} />
    ),
  }));

  return (
    <div className='table'>
      <div style={{ textAlign: 'center' }}>
        <NotificationTable
          data={data}
          columnKeys={columnKeys}
          columnKeysTranslated={columnKeysTranslated}
          editPath={`/magistrala/recepie/`}
        />
      </div>
    </div>
  );
};

export default Notifications;
