import React, { useState, useEffect, useContext } from 'react';
import { Form, Card, Divider, Select, Input, notification } from 'antd';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import axios from 'axios';
import 'react-phone-number-input/style.css';
import TextArea from 'antd/lib/input/TextArea';
import { useHistory } from 'react-router-dom';

const { Option } = Select;

const EditPattern = (props) => {
  const history = useHistory();
  const { patternId } = props.match.params;
  const [form] = Form.useForm();
  const user = useContext(UserContext);
  const [pattern, setPattern] = useState();
  const [patternGroups, setPatternGroups] = useState();

  const headers = {
    withCredentials: false,
    headers: {
      Authorization: `Bearer ${user.data.token}`,
    },
  };

  const getPattern = async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/recipe-pattern/${patternId}`, headers);
      form.setFieldsValue({ name: data.name, description: data.description, group: data.group });
      setPattern(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (patternId) {
      getPattern();
    }
  }, [patternId]);

  useEffect(() => {
    fetchPatternGroups();
  }, []);

  const fetchPatternGroups = async () => {
    const { data } = await axios.get(`${SERVER_URL}/recipe-pattern-groups`, headers);
    setPatternGroups(data);
  };

  const onFinish = async (values) => {
    try {
      if (!patternId) {
        await axios.post(`${SERVER_URL}/recipe-patterns`, values, headers);
        notification.success({
          message: 'Uspešno ste kreirali obrazac!',
          placement: 'bottomRight',
        });
      } else {
        await axios.put(`${SERVER_URL}/recipe-pattern/${patternId}`, values, headers);
        notification.success({
          message: 'Uspešno ste izmenili obrazac!',
          placement: 'bottomRight',
        });
      }
      setTimeout(() => history.push('/magistrala/patterns'), 800);
    } catch (error) {
      notification.info({
        message: error.response.data.message,
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='dashboard'>
      <div className='card-wrapper' style={{ justifyContent: 'center' }}>
        <Card title={!patternId ? 'Novi obrazac' : 'Ažuriraj obrazac'} bordered={false}>
          <Form className='form-horizontal' form={form} onFinish={(values) => onFinish(values)} layout='vertical'>
            <Form.Item
              label='NAZIV'
              name='name'
              rules={[
                {
                  required: true,
                  message: 'Molimo, unesite naziv!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label='SADRŽAJ'
              name='description'
              rules={[
                {
                  required: true,
                  message: 'Molimo, unesite sadržaj!',
                },
              ]}
            >
              <TextArea />
            </Form.Item>

            <Form.Item
              label='GRUPA'
              name='group'
              rules={[
                {
                  required: true,
                  message: 'Molimo, izaberite grupu!',
                },
              ]}
            >
              <Select>
                {patternGroups &&
                  patternGroups.length > 0 &&
                  patternGroups.map((item, index) => {
                    return (
                      <Option value={item._id} key={item._id}>
                        {item.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>

            <Divider type='horizontal' />
            <div className='text-right'>
              <button className='b2b-primary' htmlType='submit'>
                <span>{!patternId ? 'Kreiraj' : 'Azuriraj'}</span>
              </button>
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default EditPattern;
