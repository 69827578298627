import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../../App';
import DoctorTable from '../../components/tables/DoctorTable';
import { DOCTOR_URL, SERVER_URL } from '../../config';
import { notification } from 'antd';

export default function Doctors() {
  const user = useContext(UserContext);
  const [doctors, setDoctors] = useState([]);

  const headers = {
    withCredentials: false,
    headers: {
      Authorization: `Bearer ${user.data.token}`,
    },
  };

  const getDoctors = async () => {
    try {
      // const cloudDoctors = await axios.get(`${DOCTOR_URL}/melo/doctors`, headers);
      const galenaDoctors = await axios.get(`${SERVER_URL}/doctors`, headers);
      setDoctors([...galenaDoctors.data]);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getDoctors();
  }, []);

  const columnKeys = ['name', 'phone', 'cities', 'ordinations', 'email'];
  const columnKeysTranslated = ['ime', 'telefon', 'grad', 'zdrastvena ustanova', 'email'];

  const deleteHandler = async (id) => {
    try {
      await axios.delete(`${SERVER_URL}/doctor/${id}`, headers);
      setDoctors(doctors.filter((doc) => doc._id !== id));
      notification.info({
        message: 'Doktor je izbrisan',
        placement: 'bottomRight',
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className='table'>
      <div style={{ textAlign: 'center' }}>
        <DoctorTable
          deleteHandler={deleteHandler}
          data={doctors}
          columnKeys={columnKeys}
          columnKeysTranslated={columnKeysTranslated}
        />
      </div>
    </div>
  );
}
