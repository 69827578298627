import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../../App';
import PatternGroupTable from '../../components/tables/PatternGroupTable';
import { SERVER_URL } from '../../config';
import { notification } from 'antd';

export default function PatternGroups() {
  const user = useContext(UserContext);
  const [patterngroups, setPatternGroups] = useState([]);

  const getPatternGroups = async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/recipe-pattern-groups`, {
        withCredentials: false,
        headers: {
          Authorization: `Bearer ${user.data.token}`,
        },
      });

      setPatternGroups([...data]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPatternGroups();
  }, []);

  const columnKeys = ['name'];
  const columnKeysTranslated = ['naziv'];

  const deleteHandler = async (id) => {
    try {
      // await axios.delete(`${SERVER_URL}/ordination/${id}`, headers);
      // setOrdinations(ordinations.filter((doc) => doc._id !== id));
      notification.info({
        message: 'Grupa je izbrisan',
        placement: 'bottomRight',
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className='table'>
      <div style={{ textAlign: 'center' }}>
        <PatternGroupTable
          deleteHandler={deleteHandler}
          data={patterngroups}
          columnKeys={columnKeys}
          columnKeysTranslated={columnKeysTranslated}
        />
      </div>
    </div>
  );
}
