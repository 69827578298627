import React, { useState, useEffect, useContext } from 'react';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import axios from 'axios';
import AccountTable from '../../components/tables/AccountTable';

const Accounts = () => {
  const user = useContext(UserContext);
  const [accounts, setAccounts] = useState([]);

  const headers = {
    withCredentials: false,
    headers: {
      Authorization: `Bearer ${user.data.token}`,
    },
  };

  const getAccounts = async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/accounts`, headers);
      // console.log(data);
      setAccounts(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getAccounts();
  }, []);

  const columnKeys = ['drug', 'doctor', 'patient', 'date'];
  const columnKeysTranslated = ['lek', 'doktor', 'pacijent', 'datum'];

  const data = accounts.map((account) => ({
    ...account,
    drug: account.drug_agg[0]?.title.sr,
    doctor: `${account?.doctor_agg[0]?.firstName ? account.doctor_agg[0].firstName : ''} ${
      account?.doctor_agg[0]?.lastName ? account.doctor_agg[0].lastName : ''
    }`,
  }));

  return (
    <div className='table'>
      <div style={{ textAlign: 'center' }}>
        <AccountTable data={data} columnKeys={columnKeys} columnKeysTranslated={columnKeysTranslated} />
      </div>
    </div>
  );
};

export default Accounts;
