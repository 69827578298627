import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../../App';
import PatientTable from '../../components/tables/PatientTable';
import { DOCTOR_URL, SERVER_URL } from '../../config';
import { notification } from 'antd';

export default function Patients() {
  const user = useContext(UserContext);
  const [patients, setPatients] = useState([]);
  const isMeloDoctor = user.data.role.includes('admin') && !user.data.role.includes('superAdmin');

  const headers = {
    withCredentials: false,
    headers: {
      Authorization: `Bearer ${user.data.token}`,
    },
  };

  const getPatients = async () => {
    let meloLink, galenaLink;
    if (user.data.role.includes('superAdmin')) {
      // meloLink = `${DOCTOR_URL}/melo/patients`;
      galenaLink = `${SERVER_URL}/patients`;
    } else if (user.data.role.includes('doctor')) {
      galenaLink = `${SERVER_URL}/doctor/patients/${user.data.id}`;
    } else if (isMeloDoctor) {
      // meloLink = `${DOCTOR_URL}/melo/doctor-patients/${user.data.id}`;
      galenaLink = `${SERVER_URL}/doctor/patients/${user.data.id}`;
    }

    try {
      console.log(user.data.role);
      if (user.data.role.includes('doctor')) {
        const { data } = await axios.get(galenaLink, headers);
        console.log(data);
        setPatients(data);
      } else {
        const galena = await axios.get(galenaLink, headers);
        // const melo = await axios.get(meloLink, headers);
        setPatients([...galena.data]);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getPatients();
  }, []);

  console.log(patients);

  const columnKeys = ['name', 'phone', 'city', 'email'];
  const columnKeysTranslated = ['ime', 'telefon', 'grad', 'email'];

  const deleteHandler = async (id) => {
    try {
      await axios.delete(`${SERVER_URL}/patient/${id}`, headers);
      setPatients(patients.filter((doc) => doc._id !== id));
      notification.info({
        message: 'Pacijent je izbrisan',
        placement: 'bottomRight',
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className='table'>
      <div style={{ textAlign: 'center' }}>
        <PatientTable
          deleteHandler={deleteHandler}
          data={patients}
          columnKeys={columnKeys}
          columnKeysTranslated={columnKeysTranslated}
        />
      </div>
    </div>
  );
}
