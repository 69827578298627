import { useEffect, useState } from 'react';
import { Card, notification } from 'antd';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { SERVER_URL } from '../../config';

const backgroundStyle = {
  width: '100vw',
  height: '100vh',
  backgroundImage: `url(/login-bcg.png)`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat'
};

const loginCardStyle = {
  borderRadius: '20px',
  background: 'rgba(255, 255, 255, 0.8)',
};

const companyLogoStyle = {
  maxWidth: '250px',
  marginBottom: '50px'
};

const Verify = (props) => {
  const { tokenId } = props.match.params;
  const [message, setMessage] = useState('');

  const activate = async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/email-verification/${tokenId}`);
      setMessage(data.message);
      notification.success({
        message: data.message,
        placement: "bottomRight"
      });
    } catch (error) {
      setMessage(error.response.data.message);
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (tokenId) {
      activate();
    }
  }, [tokenId]);

  return (
    <div className="log" style={backgroundStyle}>
      <div className="card-wrapper">
        <Card className="login-header" bordered={false} style={loginCardStyle}>
          <img style={companyLogoStyle} className="login-logo" src="/galena_logo.svg" alt="Universal Logo" />
          <p style={{ fontSize: "1.5rem" }}>{message}</p>
          <Link to='/login'>Login</Link>
        </Card>
      </div>
    </div>
  );
};

export default Verify;