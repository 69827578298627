import React, { useState, useEffect, useContext } from 'react';
import { Form, Input, Button, Card, Select, Result, notification, Modal } from 'antd';
import UploadBox from '../base/UploadBox';
import { GalleryModal } from './';
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { UserContext } from '../../App';

const tailLayout = {
  wrapperCol: { span: 24 },
};

const layout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

const initialValues = {
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  password: undefined,
  phone: '',
  avatar: undefined,
  role: [],
};

const UserForm = ({
  data,
  updateHandler,
  createHandler,
  result,
  setResult,
  SERVER_URL,
  token,
  showModal,
  setShowModal,
  profile,
}) => {
  const [form] = Form.useForm();
  const currentuser = useContext(UserContext);
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const [image, setImage] = useState();
  initialValues.legalType = currentuser.data && currentuser.data.role.includes('superAdmin') ? 'PRIVATE' : 'CLIENT';
  let user = data ? { ...data } : initialValues;
  const isNew = data ? false : true;
  const [deletePop, setDeletePop] = useState();

  useEffect(() => {
    if (data && data.avatar) setImage(data.avatar.url);
  }, [data]);

  const onFinish = (values) => {
    if (isNew) {
      createHandler(values);
    } else {
      updateHandler(values);
    }
    setShowModal(!showModal);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Greška:', errorInfo);
  };

  const avatarHandler = () => {
    setModal({ ...modal, visible: true, formKey: 'avatar' });
  };

  const deleteAvatarHandler = () => {
    setImage(null);
    form.setFieldsValue({ avatar: null });
  };

  const onInsert = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
    setImage(values);
  };

  const deleteUserHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/users/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Stavka je obrisana.',
        placement: 'bottomRight',
      });
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Molim Vas da pokušate kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='dashboard'>
      <div className='card-wrapper' style={{ height: '100vh' }}>
        {/* eslint-disable-next-line no-useless-concat */}
        <Card title={`${isNew ? 'Novi korisnik' : 'Izmena korisnika'}`} bordered={false}>
          <CloseOutlined className='x-btn' onClick={() => setShowModal(false)} />
          {profile && (
            <div className='delete-user' onClick={() => setDeletePop(!deletePop)}>
              Deaktivacija korisnika <DeleteOutlined />
            </div>
          )}
          {!result && (
            <Form
              {...layout}
              name='basic'
              initialValues={user}
              onFinish={(values) => onFinish(values, isNew)}
              onFinishFailed={onFinishFailed}
              layout='horizontal'
              form={form}
            >
              <Form.Item /*label='Avatar'*/ className='upload-wrapper right' name='avatar'>
                <UploadBox
                  editHandler={avatarHandler}
                  deleteHandler={deleteAvatarHandler}
                  image={image}
                  index={0}
                  name='avatar'
                />
              </Form.Item>

              <Form.Item
                /*label='Email'*/
                name='email'
                rules={[
                  {
                    required: true,
                    message: 'Molim Vas da unesete email!',
                  },
                ]}
              >
                <Input placeholder='Email' />
              </Form.Item>

              <Form.Item
                /*label='First name'*/
                name='firstName'
                rules={[
                  {
                    required: true,
                    message: 'Molim Vas da unesete ime!',
                  },
                ]}
              >
                <Input placeholder='Ime' />
              </Form.Item>

              <Form.Item
                /*label='Last name'*/
                name='lastName'
                rules={[
                  {
                    required: true,
                    message: 'Molim Vas da unesete prezime!',
                  },
                ]}
              >
                <Input placeholder='Prezime' />
              </Form.Item>

              <Form.Item /*label='Phone'*/ name='phone'>
                <Input placeholder='Telefon' />
              </Form.Item>

              {isNew && (
                <Form.Item
                  /*label='Password'*/
                  name='password'
                  rules={[
                    {
                      required: true,
                      message: 'Molim Vas da unesete password!',
                    },
                  ]}
                >
                  <Input.Password placeholder='Password' />
                </Form.Item>
              )}

              <Form.Item
                /*label='Role'*/
                name='role'
                rules={[
                  {
                    required: true,
                    message: 'Molim Vas da odaberete role!',
                  },
                ]}
              >
                <Select
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownAlign={{ offset: [0, -140] }}
                  placeholder='Role'
                  onChange={(e) => console.log(e)}
                >
                  <Select.Option value='superAdmin'>Super admin</Select.Option>
                  <Select.Option value='companyAdmin'>Company admin</Select.Option>
                  <Select.Option value='company'>Company</Select.Option>
                  <Select.Option value='production'>Production</Select.Option>
                </Select>
              </Form.Item>

              {!isNew && (
                <Form.Item
                  /*label='Status'*/
                  name='status'
                  rules={[
                    {
                      required: true,
                      message: 'Molim Vas da odaberete status!',
                    },
                  ]}
                >
                  <Select getPopupContainer={(trigger) => trigger.parentNode} dropdownAlign={{ offset: [0, -170] }}>
                    <Select.Option value='ACTIVE'>AKTIVAN</Select.Option>
                    <Select.Option value='SUSPENDED'>SUSPENDOVAN</Select.Option>
                    <Select.Option value='DELETED'>OBRISAN</Select.Option>
                    <Select.Option value='WAITING_FOR_ACTIVATION'>ČEKA_NA_AKTIVACIJU</Select.Option>
                  </Select>
                </Form.Item>
              )}

              <Form.Item {...tailLayout}>
                <div>
                  <button className='b2b-primary' htmlType='submit' style={{ float: 'right' }}>
                    {data ? 'Snimi promene' : 'Dodaj korisnika'}
                  </button>
                  <span
                    style={{
                      "position": "relative",
                      "display": "inline-block",
                      "fontWeight": 700,
                      "whiteSpace": "nowrap",
                      "textAlign": "center",
                      "padding": "10px 15px 4px 15px",
                      "height": "38px",
                      "letterSpacing": "0.05em",
                      "fontSize": "13px",
                      "transition": "none",
                      "background": "#fff",
                      "color": "#a1b8d0",
                      "border": "2px solid #a1b8d0",
                      "borderRadius": "5px",
                      "lineHeight": "normal",
                      "cursor": "pointer",
                      float: 'right', marginRight: '10px'
                    }}
                    onClick={() => {
                      setShowModal(!showModal);
                      form.resetFields();
                    }}
                  >
                    Poništi
                  </span>
                </div>
              </Form.Item>
            </Form>
          )}
          {result && (
            <Result
              title='Korisnik je kreiran i verifikacioni mejl je poslat'
              extra={
                <Button
                  type='primary'
                  key='console'
                  onClick={() => {
                    setResult(false);
                    window.location.reload();
                  }}
                >
                  Ok
                </Button>
              }
            />
          )}
        </Card>

        {modal.visible && (
          <GalleryModal
            className='gallery-modal'
            visible={modal.visible}
            limit={modal.limit}
            formKey={modal.formKey}
            imageSavePath='public/images/users/'
            imageType='data'
            imageHeight={200}
            SERVER_URL={SERVER_URL}
            token={token}
            form={form}
            onCancel={() => setModal({ ...modal, visible: false })}
            onInsert={(values) => onInsert(values, modal.formKey)}
          />
        )}
        <Modal
          className='deleteModal'
          centered={true}
          visible={deletePop}
          onCancel={() => setDeletePop(!deletePop)}
          onOk={() => deleteUserHandler(user._id)}
          okText='Da'
          cancelText='Ne'
        >
          <p>Da li ste sigurni da želite da deaktivirate</p>
        </Modal>
      </div>
    </div>
  );
};

export default UserForm;
