const SystemicDevided = ({ recepie }) => (
  <div>
    <h1 className='print-input' style={{ fontSize: '1.5rem', paddingBottom: '0.5rem' }}>RP/</h1>
    <div className='print-input'>
      <label>API: </label>
      {recepie.drugs?.map((drug, i) => (
        <div key={i} style={{
          display: 'flex',
          flexDirection: "row",
          justifyContent: 'space-evenly',
          width: '100%',
          padding: '0 1rem',
          background: 'white'
        }}>
          <div style={{ margin: 0, background: 'white', width: '-webkit-fill-available' }}>
            {drug.name}
          </div>
          <div style={{ marginLeft: '1rem', display: 'flex', flexDirection: 'row' }}>
            <b>{drug.dosage}</b>
            <p style={{ marginLeft: '0.3rem' }}>g</p>
          </div>
        </div>
      ))}
    </div>
    <div className='print-input'>
      <label>Excipiens ad: </label>
      <b>{recepie.systematicDevidedExcipiens}</b>
      <span style={{ marginLeft: '0.3rem' }}>{recepie.systematicDevidedExcipiensMeasure}</span>
    </div>
    <div className='print-input'>
      <label>M.f.: </label>
      <b>{recepie.systematicDevidedMf}</b>
    </div>
    <div className='print-input'>
      <label>D.t.d. N: </label>
      <b>{recepie.systematicDevidedDtdN}</b>
    </div>
    <div className='print-input'>
      <label>D.s.: </label>
      <b>{recepie.systematicDevidedDs}</b>
    </div>
  </div>
);

export default SystemicDevided;