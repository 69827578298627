import React from 'react';
import { Form, Select, Input } from 'antd';

const { Option } = Select;
const style = {
  background: 'white',
  margin: 0,
  padding: '0.2rem 1rem'
};

export default function SystematicUndevided({ activeSubstances, selectedDrugs, setSelectedDrugs }) {
  const changeDrugDosage = (dosage, index) => {
    const copy = [...selectedDrugs];
    copy[index].dosage = dosage;
    setSelectedDrugs(copy);
  };

  const setMeasure = (measure, index) => {
    const copy = [...selectedDrugs];
    copy[index].measure = measure;
    setSelectedDrugs(copy);
  };

  const selectDrug = drug => {
    setSelectedDrugs([...selectedDrugs, { name: drug }]);
  };

  const deselectDrug = drug => {
    setSelectedDrugs(prevState => prevState.filter(d => d.name !== drug));
  };

  return (
    <>
      <h1 style={{ ...style, fontSize: '1.5rem', paddingBottom: '0.5rem' }}>RP/</h1>
      <div style={{
        display: 'flex',
        flexDirection: "row"
      }}>
        <Form.Item label='API' style={{ ...style, flex: 1 }}>
          <Select
            placeholder='Odaberite lek'
            showArrow
            showSearch
            mode='multiple'
            defaultValue={selectedDrugs?.map(d => d.name)}
            onSelect={selectDrug}
            onDeselect={deselectDrug}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {activeSubstances?.map((sub, i) => (
              <Option key={i} value={sub}>{sub}</Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      {selectedDrugs?.map((drug, i) => (
        <div key={i} style={{
          display: 'flex',
          flexDirection: "row",
          padding: '0.2rem 1rem',
          background: 'white'
        }}>
          <span style={{ color: 'crimson' }}>*</span>
          <div style={{ margin: 0, background: 'white', width: '-webkit-fill-available' }}>
            {drug.name}
          </div>
          <Input type='number' required onChange={dosage => changeDrugDosage(dosage.target.value, i)} style={{ width: '4rem' }} showArrow defaultValue={drug.dosage} />
          <div style={style}>
            <Select placeholder='Jedinica' style={{ minWidth: '6rem' }} onChange={measure => setMeasure(measure, i)} defaultValue={drug.measure}>
              <Option value='g' key='g'>g</Option>
              <Option value='mg/ml' key='mg/ml'>mg/ml</Option>
            </Select>
          </div>
        </div>
      ))}
      <div style={{
        display: 'flex',
        flexDirection: "row"
      }}>
        <Form.Item name='systematicUndevidedExcipiens' label='Excipiens ad' style={{ ...style, flex: 1 }}>
          <Input type='number' />
        </Form.Item>
        <Form.Item name='systematicUndevidedExcipiensMeasure' style={style}>
          <Select placeholder='Odaberite jedinicu' showArrow>
            <Option value='g' key='g'>g</Option>
            <Option value='ml' key='ml'>ml</Option>
          </Select>
        </Form.Item>
      </div>
      <Form.Item name='systematicUndevidedMf' label='M.f.' style={style}>
        <Select placeholder='Odaberite' showArrow>
          <Option value='pulvis' key='pulvis'>pulvis</Option>
          <Option value='sirupus' key='sirupus'>sirupus</Option>
          <Option value='solutio' key='solutio'>solutio</Option>
          <Option value='suspensio' key='suspensio'>suspensio</Option>
        </Select>
      </Form.Item>
      <Form.Item name='systematicUndevidedDs' label='D.s.' style={{ ...style, borderBottom: '2px solid #A0BFDD' }}>
        <Input />
      </Form.Item>
    </>
  );
}
