import React, { useState, useEffect, useContext } from 'react';
import { Form, Card, Divider, Select, Input, notification } from 'antd';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import axios from 'axios';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import TextArea from 'antd/lib/input/TextArea';
import { useHistory } from 'react-router-dom';

const { Option } = Select;

const EditPatternGroup = (props) => {
  const history = useHistory();
  const { patterngroupId } = props.match.params;
  const [form] = Form.useForm();
  const user = useContext(UserContext);
  const [patternGroup, setPatternGroup] = useState();

  const headers = {
    withCredentials: false,
    headers: {
      Authorization: `Bearer ${user.data.token}`,
    },
  };

  const getPatternGroup = async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/recipe-pattern-group/${patterngroupId}`, headers);
      form.setFieldsValue({ name: data.name });
      setPatternGroup(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (patterngroupId) {
      getPatternGroup();
    }
  }, [patterngroupId]);

  const onFinish = async (values) => {
    try {
      if (!patterngroupId) {
        await axios.post(`${SERVER_URL}/recipe-pattern-groups`, values, headers);
        notification.success({
          message: 'Uspešno ste kreirali grupu obrazaca!',
          placement: 'bottomRight',
        });
      } else {
        await axios.put(`${SERVER_URL}/recipe-pattern-group/${patterngroupId}`, values, headers);
        notification.success({
          message: 'Uspešno ste izmenili grupu obrazaca!',
          placement: 'bottomRight',
        });
      }
      setTimeout(() => history.push('/magistrala/pattern-groups'), 800);
    } catch (error) {
      notification.info({
        message: error.response.data.message,
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='dashboard'>
      <div className='card-wrapper' style={{ justifyContent: 'center' }}>
        <Card title={!patterngroupId ? 'Nova grupa obrazaca' : 'Ažuriraj grupu obrazaca'} bordered={false}>
          <Form className='form-horizontal' form={form} onFinish={(values) => onFinish(values)} layout='vertical'>
            <Form.Item
              label='NAZIV'
              name='name'
              rules={[
                {
                  required: true,
                  message: 'Molimo, unesite naziv!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Divider type='horizontal' />
            <div className='text-right'>
              <button className='b2b-primary' htmlType='submit'>
                <span>{!patterngroupId ? 'Kreiraj' : 'Azuriraj'}</span>
              </button>
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default EditPatternGroup;
