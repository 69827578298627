import React, { useContext, useState } from 'react';
import { Table, Input, Button, Modal, Col, notification } from 'antd';
import { UserContext } from '../../App';
import { Link, useHistory } from 'react-router-dom';
import { SearchOutlined, EditOutlined, PrinterOutlined, InfoCircleOutlined, CopyOutlined } from '@ant-design/icons';
import ReactToPrint from 'react-to-print';
import { SERVER_URL } from '../../config';
import PrintRecepie from '../modals/PrintRecepie';
import axios from 'axios';

let searchInput;

export default function RecepieTable({ printRef, columnKeys, data, columnKeysTranslated }) {
  const user = useContext(UserContext);
  const history = useHistory();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [recepie, setRecepie] = useState({});

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const copyRecipe = async (record) => {
    try {
      delete record._id;
      delete record.createdAt;
      delete record.updatedAt;

      const call = await axios.post(
        `${SERVER_URL}/copy-recepie`,
        { record },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${user.data.token}` },
        },
      );

      notification.success({ message: 'Uspešno kopiran recept', placement: 'bottomRight' });

      history.push('/magistrala');
      history.push('/magistrala/recepies');
    } catch (error) {
      console.log(error);
      notification.error({
        message: error,
      });
    }
  };

  const columns = columnKeys?.map((item, index) => ({
    key: item,
    title: columnKeysTranslated.map((x) => x)[index],
    dataIndex: ['title', 'category'].includes(item) ? [item, user.language ? user.language.selected.code : ''] : item,
    ...getColumnSearchProps(
      ['title', 'category'].includes(item) ? [item, user.language ? user.language.selected.code : ''] : item,
    ),
  }));

  const toggleModal = (result) => {
    setIsOpenModal(true);
    setRecepie(result);
  };

  columns.push({
    title: 'Postupak',
    render: (record) => (
      <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '13px' }}>
        <div style={{ cursor: 'pointer' }}>
          <Link to={`/magistrala/recepie/${record._id}`}>
            <EditOutlined title='Izmeni recept' />
          </Link>
          <PrinterOutlined
            onClick={() => toggleModal(record)}
            style={{ marginLeft: '0.5rem' }}
            title='Stampaj recept'
          />
          <CopyOutlined onClick={() => copyRecipe(record)} style={{ marginLeft: '0.5rem' }} title='Kopiraj recept' />
        </div>
      </div>
    ),
  });

  return (
    <div>
      <div className='actions-block'>
        <Link to='/magistrala/recepie/new'>
          <button className='b2b-primary'>Kreiraj recept</button>
        </Link>
      </div>
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        pagination={{
          defaultPageSize: 20,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
        locale={{
          emptyText: (
            <span>
              <InfoCircleOutlined style={{ fontSize: '1.9rem', marginTop: '1.5rem' }} />
              <p style={{ marginBottom: '1.5rem', marginTop: '0.3rem' }}>Nema recepata</p>
            </span>
          ),
        }}
      />
      <Modal
        closable={false}
        visible={isOpenModal}
        onCancel={() => setIsOpenModal(false)}
        footer={null}
        centered
        className='custom-modal'
        style={{
          borderRadius: '1rem',
          border: '1px solid #A0BFDD',
          outline: '2px solid #A0BFDD',
          outlineOffset: '2px',
        }}
      >
        <PrintRecepie recepie={recepie} ref={printRef} key={recepie._id} />

        <Col xs={{ span: 24, offset: 0 }} className='report-form-button-col'>
          <ReactToPrint
            trigger={() => (
              <div style={{ display: 'flex', justifyContent: 'end', margin: '10px 15px' }}>
                <button className='b2b-primary'>
                  <span>Štampaj</span>
                </button>
              </div>
            )}
            content={() => printRef.current}
            documentTitle={'Recept'}
          />
        </Col>
      </Modal>
    </div>
  );
}
