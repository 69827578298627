import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../../App';
import PatternTable from '../../components/tables/PatternTable';
import { SERVER_URL } from '../../config';
import { notification } from 'antd';

export default function PatternGroups() {
  const user = useContext(UserContext);
  const [patterns, setPatterns] = useState([]);

  const getPatterns = async () => {
    try {
      let { data } = await axios.get(`${SERVER_URL}/recipe-patterns`, {
        withCredentials: false,
        headers: {
          Authorization: `Bearer ${user.data.token}`,
        },
      });

      for (let i = 0; i < data.length; i++) {
        data[i].group = data[i].group.name;
      }

      setPatterns([...data]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPatterns();
  }, []);

  const columnKeys = ['name', 'description', 'group'];
  const columnKeysTranslated = ['naziv', 'sadržaj', 'grupa'];

  return (
    <div className='table'>
      <div style={{ textAlign: 'center' }}>
        <PatternTable data={patterns} columnKeys={columnKeys} columnKeysTranslated={columnKeysTranslated} />
      </div>
    </div>
  );
}
