import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../../App';
import OrdinationTable from '../../components/tables/OrdinationTable';
import { DOCTOR_URL, SERVER_URL } from '../../config';
import { notification } from 'antd';

export default function Ordinations() {
  const user = useContext(UserContext);
  const [ordinations, setOrdinations] = useState([]);
  const isMeloDoctor = user.data.role.includes('admin') && !user.data.role.includes('superAdmin');

  const headers = {
    withCredentials: false,
    headers: {
      Authorization: `Bearer ${user.data.token}`,
    },
  };

  const getOrdinations = async () => {
    let // meloLink,
      galenaLink;
    if (user.data.role.includes('superAdmin')) {
      // meloLink = `${DOCTOR_URL}/melo/ordinations`;
      galenaLink = `${SERVER_URL}/ordinations`;
    } else if (user.data.role.includes('doctor')) {
      // meloLink = `${DOCTOR_URL}/melo/doctor-ordination/${user.data.id}`;
      galenaLink = `${SERVER_URL}/doctor/ordinations/${user.data.id}`;
    } else if (isMeloDoctor) {
      // meloLink = `${DOCTOR_URL}/melo/doctor-ordination/${user.data.id}`;
    }

    try {
      if (isMeloDoctor) {
        // const { data } = await axios.get(meloLink, headers);
        const { data } = await axios.get(galenaLink, headers);
        setOrdinations(data);
      } else if (user.data.role.includes('doctor')) {
        const { data } = await axios.get(galenaLink, headers);
        setOrdinations(data);
      } else {
        const galena = await axios.get(galenaLink, headers);
        // const melo = await axios.get(meloLink, headers);
        setOrdinations([...galena.data]);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getOrdinations();
  }, []);

  const columnKeys = ['name', 'phone', 'cities', 'address', 'email'];
  const columnKeysTranslated = ['naziv', 'telefon', 'grad', 'adresa', 'email'];

  const deleteHandler = async (id) => {
    try {
      await axios.delete(`${SERVER_URL}/ordination/${id}`, headers);
      setOrdinations(ordinations.filter((doc) => doc._id !== id));
      notification.info({
        message: 'Ordinacija je izbrisan',
        placement: 'bottomRight',
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className='table'>
      <div style={{ textAlign: 'center' }}>
        <OrdinationTable
          deleteHandler={deleteHandler}
          data={ordinations}
          columnKeys={columnKeys}
          columnKeysTranslated={columnKeysTranslated}
        />
      </div>
    </div>
  );
}
