import React, { useState, useEffect, useContext } from 'react';
import { Form, Card, Divider, Select, Input, notification } from 'antd';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import axios from 'axios';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import TextArea from 'antd/lib/input/TextArea';
import { useHistory } from 'react-router-dom';

const { Option } = Select;

const NewOrdination = (props) => {
  const history = useHistory();
  const { ordinationId } = props.match.params;
  const [form] = Form.useForm();
  const user = useContext(UserContext);
  const [ordination, setOrdination] = useState({});
  const [cities, setCities] = useState([]);
  const [startHour, setStartHour] = useState();
  const [endHour, setEndHour] = useState();
  const [phone, setPhone] = useState();

  const headers = {
    withCredentials: false,
    headers: {
      Authorization: `Bearer ${user.data.token}`,
    },
  };

  const getOrdination = async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/ordination/${ordinationId}`, headers);
      setOrdination(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (ordinationId) {
      getOrdination();
    }
  }, [ordinationId]);

  useEffect(() => {
    if (ordination) {
      setStartHour(ordination.workingHours?.split(' - ')[0]);
      setEndHour(ordination.workingHours?.split(' - ')[1]);
    }
  }, [ordination]);

  if (ordination?.name) {
    form.setFieldsValue({
      ...ordination,
      phone: ordination.phone[0],
    });
  }

  const getCities = async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/cities`, headers);
      setCities(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getCities();
  }, []);

  const onFinish = async (values) => {
    try {
      values.workingHours = `${startHour} - ${endHour}`;
      if (phone) {
        values.phone = phone;
      }
      if (values.country === undefined) values.country = 'Srbija';
      if (!ordinationId) {
        const { data } = await axios.post(`${SERVER_URL}/ordinations`, values, headers);
        notification.success({
          message: data.message,
          placement: 'bottomRight',
        });
      } else {
        const { data } = await axios.put(`${SERVER_URL}/ordination/${ordinationId}`, values, headers);
        notification.success({
          message: data.message,
          placement: 'bottomRight',
        });
      }
      setTimeout(() => history.push('/magistrala/ordinations'), 800);
    } catch (error) {
      notification.info({
        message: error.response.data.message,
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='dashboard'>
      <div className='card-wrapper' style={{ justifyContent: 'center' }}>
        <Card title={!ordinationId ? 'Nov profil zdrastvene ustanove' : 'Azuriraj zdrastvenu ustanovu'} bordered={false}>
          <Form className='form-horizontal' form={form} onFinish={(values) => onFinish(values)} layout='vertical'>
            <Form.Item
              label='NAZIV'
              name='name'
              rules={[
                {
                  required: true,
                  message: 'Molimo, unesite naziv!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label='EMAIL'
              name='email'
              rules={[
                {
                  required: true,
                  message: 'Molimo, unesite email!',
                },
              ]}
            >
              <Input type='email' />
            </Form.Item>

            <Form.Item label='ADRESA' name='address'>
              <Input />
            </Form.Item>

            <Form.Item
              label='PIB'
              name='pib'
              rules={[
                {
                  required: true,
                  message: 'Molimo Vas, unesite PIB!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label='TELEFON' name='phone'>
              <PhoneInput
                style={{ border: '1px solid #d9d9d9', borderRadius: '2px', padding: '4px 11px' }}
                international
                defaultCountry='RS'
                countryCallingCodeEditable={false}
                value={phone}
                onChange={setPhone}
              />
            </Form.Item>

            <Form.Item label='GRAD' name='city'>
              <Select
                showArrow={true}
                showSearch
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {cities?.map((city) => (
                  <Option key={city._id} value={city.title}>
                    {city.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label='DRŽAVA' name='country'>
              <Input defaultValue='Srbija' />
            </Form.Item>

            <Form.Item label='RADNO VREME' name='workingHours'>
              <div style={{ display: 'flex', flexDirection: "row" }}>
                <Input
                  type='time'
                  onChange={(e) => setStartHour(e.target.value)}
                  value={startHour && startHour}
                />
                <Input
                  type='time'
                  onChange={(e) => setEndHour(e.target.value)}
                  value={endHour && endHour}
                />
              </div>
            </Form.Item>

            <Form.Item label='WEBSAJT' name='website'>
              <Input />
            </Form.Item>

            <Form.Item label='OPIS' name='description'>
              <TextArea rows={3}></TextArea>
            </Form.Item>

            <Divider type='horizontal' />
            <div className='text-right'>
              <button className='b2b-primary' htmlType='submit'>
                <span>{!ordinationId ? 'Kreiraj' : 'Azuriraj'}</span>
              </button>
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default NewOrdination;
