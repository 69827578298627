import React from 'react';
import SystemicUndevided from './recepie/SystemicUndevided';
import SystemicDevided from './recepie/SystemicDevided';
import LocalUndevided from './recepie/LocalUndevided';
import LocalDevided from './recepie/LocalDevided';

const PrintRecepie = React.forwardRef(({ recepie }, ref) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }} ref={ref}>
      <h2
        style={{
          textTransform: 'uppercase',
          padding: '.5rem 1rem',
          background: '#E1E8F3',
          color: '#787F8A',
          borderTopLeftRadius: '1rem',
          borderTopRightRadius: '1rem',
          borderBottom: '2px solid #A0BFDD',
          margin: 0,
        }}
      >
        Zdrastvena ustanova/privatna praksa
      </h2>
      <div className='print-field'>
        <label>Naziv: </label>
        <input style={{ border: 'none' }} defaultValue={recepie.ordinationName} readOnly />
      </div>
      <div className='print-field'>
        <label>Adresa: </label>
        <input style={{ border: 'none' }} defaultValue={recepie.ordinationAddress} readOnly />
      </div>
      <div className='print-field'>
        <label>Telefon: </label>
        <input style={{ border: 'none' }} defaultValue={recepie.ordinationPhone} readOnly />
      </div>

      <h2 className='print-header'>
        Pacijent
      </h2>
      <div className='print-field'>
        <label>Ime i prezime: </label>
        <input
          style={{ border: 'none' }}
          defaultValue={recepie.patientName}
          readOnly
        />
      </div>
      <div className='print-field'>
        <label>Telefon: </label>
        <input style={{ border: 'none' }} defaultValue={recepie.patientPhone} readOnly />
      </div>
      <div className='print-field'>
        <label>Datum rodjenja: </label>
        <input style={{ border: 'none' }} defaultValue={recepie.dateOfBirth} readOnly />
      </div>

      <h2 className='print-header'>
        Naziv ili šifra dijagnoze
      </h2>
      <div className='print-field'>
        <input style={{ border: 'none' }} defaultValue={recepie.diagnoseName} readOnly />
      </div>

      {recepie.type === 'galenic' ?
        <>
          <h2 className='print-header'>
            da li navedeni magistralni lek/preparat ima sistemsko ili lokalno delovanje?
          </h2>
          <div>
            <div className='print-field'>
              <input
                type='radio'
                checked={recepie.usecase === 'systematic'}
                readOnly
              />
              <label style={{ marginLeft: '0.6rem' }}>Sistemsko</label>
            </div>
            <div className='print-field'>
              <input
                type='radio'
                checked={recepie.usecase === 'local'}
                readOnly
              />
              <label style={{ marginLeft: '0.6rem' }}>Lokalno</label>
            </div>
          </div>

          <h2 className='print-header'>
            da li je navedeni magistralni lek/preparat podeljen ili nepodeljen?
          </h2>
          <div>
            <div className='print-field'>
              <input
                type='radio'
                checked={recepie.pharmaShape === 'devided'}
                readOnly
              />
              <label style={{ marginLeft: '0.6rem' }}>Podeljen</label>
            </div>
            <div className='print-field'>
              <input
                type='radio'
                checked={recepie.pharmaShape === 'undevided'}
                readOnly
              />
              <label style={{ marginLeft: '0.6rem' }}>Nepodeljen</label>
            </div>
          </div>

          {recepie.usecase === 'systematic'
            ? recepie.pharmaShape === 'undevided'
              ? <SystemicUndevided recepie={recepie} />
              : recepie.pharmaShape === 'devided'
                ? <SystemicDevided recepie={recepie} />
                : null
            : recepie.pharmaShape === 'undevided'
              ? <LocalUndevided recepie={recepie} />
              : recepie.pharmaShape === 'devided'
                ? <LocalDevided recepie={recepie} />
                : null
          }
        </>
        :
        <>
          <h2
            style={{
              textTransform: 'uppercase',
              padding: '.3rem 1rem',
              background: '#E1E8F3',
              color: '#787F8A',
              borderBottom: '2px solid #A0BFDD',
              margin: 0,
            }}
          >
            recept
          </h2>
          <h2 style={{
            margin: 0,
            padding: '0.2rem .6rem',
            fontSize: '1.5rem',
          }}
          >
            RP/
          </h2>
          <textarea style={{ height: '170px', border: 'none', resize: 'none' }} defaultValue={recepie.custom} readOnly />
        </>
      }

      <h2 className='print-header'>
        Vrsta recepta
      </h2>
      <div className='print-field'>
        <input
          type='radio'
          checked={recepie.recepieType === 'non-renewable'}
          readOnly
        />
        <label style={{ marginLeft: '0.6rem' }}>Neobnovljiv</label>
      </div>
      <div className='print-field'>
        <div style={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
          <input
            type='radio'
            checked={recepie.recepieType === 'renewable'}
            readOnly
          />
          <label style={{ marginLeft: '0.6rem' }}>Obnovljiv</label>
          {recepie.recepieType === 'renewable' &&
            <input style={{ marginLeft: '1.2rem', border: 'none' }} defaultValue={recepie.repetition} readOnly />
          }
        </div>
      </div>
      <div className='print-field'>
        <div style={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
          <input
            type='radio'
            checked={recepie.recepieType === 'unique'}
            readOnly
          />
          <label style={{ marginLeft: '0.6rem' }}>Poseban</label>
          {recepie.recepieType === 'unique' &&
            <input style={{ marginLeft: '1.2rem', border: 'none' }} defaultValue={recepie.narcoticNum} readOnly />
          }
        </div>
      </div>
      <div className='print-field'>
        <input
          type='radio'
          checked={recepie.recepieType === 'limited'}
          readOnly
        />
        <label style={{ marginLeft: '0.6rem' }}>Ograničen</label>
      </div>

      <h2 style={{ padding: '.2rem .8rem', margin: 0 }}>Napomena</h2>
      <div className='recepie-input'>
        <textarea style={{ height: '80px', border: 'none', resize: 'none' }} defaultValue={recepie.prescriptionNote} readOnly />
      </div>

      <div className='print-field' style={{ borderTop: '2px solid #A0BFDD' }}>
        <label>Broj potvrde:</label>
        <input style={{ border: 'none', marginLeft: '0.2rem' }} defaultValue={recepie.drugID} readOnly />
      </div>

      <div className='print-field'>
        <label>Datum propisivanja leka:</label>
        <input
          style={{ border: 'none', marginLeft: '0.2rem' }}
          type='date'
          defaultValue={recepie.prescriptionDate}
          readOnly
        />
      </div>

      <div className='print-field'>
        <label>Potpis i faksimil lekara:</label>
        <input
          style={{ border: 'none', marginLeft: '0.2rem' }}
          defaultValue={recepie.doctorSignature}
          readOnly
        />
      </div>

      <div className='print-field'>
        <label>Datum izdavanja leka:</label>
        <input
          style={{ border: 'none', marginLeft: '0.2rem' }}
          type='date'
          defaultValue={recepie.issuanceDate}
          readOnly
        />
      </div>

      <div className='print-field'>
        <label>Potpis i faksimil farmaceuta:</label>
        <input style={{ border: 'none', marginLeft: '0.2rem' }} defaultValue={recepie.pharmSignature} readOnly />
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '0.5rem' }}>
        <img src='/galena_logo.svg' alt='galena' width={'100px'} />
      </div>
    </div>
  );
});

export default PrintRecepie;
