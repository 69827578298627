import ROUTES from './routeConfig';

const SERVER_URL =
  process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;

const DOCTOR_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_DOCTOR_SERVER_PROD
    : process.env.REACT_APP_DOCTOR_SERVER_DEV;

const SOCKET_URL =
  process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_SOCKET_URL : process.env.REACT_APP_DEV_SOCKET_URL;

export { ROUTES, SERVER_URL, DOCTOR_URL, SOCKET_URL };
