import React, { useState, useEffect, useContext, useRef } from 'react';
import { UserContext } from '../../App';
import axios from 'axios';
import { SERVER_URL } from '../../config';
import RecepieTable from '../../components/tables/RecepieTable';

export default function Recepies() {
  const [recepies, setRecepies] = useState([]);
  const user = useContext(UserContext);
  const printRef = useRef();

  const headers = {
    withCredentials: false,
    headers: { Authorization: `Bearer ${user.data.token}` },
  };

  const getRecepies = async () => {
    try {
      let url = user.data.id;
      if (user.data.role.includes('superAdmin')) {
        url = '';
      }
      const { data } = await axios.get(`${SERVER_URL}/recepies/${url}`, headers);
      setRecepies(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getRecepies();
  }, []);

  const columnKeys = ['number', 'doctorName', 'patientName', 'date', 'status'];
  const columnKeysTranslated = ['redni broj', 'doktor', 'pacijent', 'datum zaprimanja', 'status'];

  const data = recepies.map((recepie) => ({
    ...recepie,
    number: recepie.workOrderNumber,
    doctorName: recepie.doctorSignature,
    status:
      recepie.status === 'CREATED'
        ? 'Kreiran'
        : recepie.status === 'RECEIVED'
        ? 'Zaprimljen'
        : recepie.status === 'IN_PRODUCTION'
        ? 'U izradi'
        : 'Izradjen',
    date: recepie.receivedDate
      ? new Date(recepie.receivedDate).toLocaleDateString('sr-Latn-RS', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
      : '',
  }));

  return (
    <div className='table'>
      <div style={{ textAlign: 'center' }}>
        <RecepieTable
          data={data}
          columnKeys={columnKeys}
          printRef={printRef}
          columnKeysTranslated={columnKeysTranslated}
        />
      </div>
    </div>
  );
}
