import React, { useContext } from 'react';
import { Table, Input, Button, Popconfirm } from 'antd';
import { UserContext } from '../../App';
import { Link } from 'react-router-dom';
import { SearchOutlined, EditOutlined, DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';

export default function DoctorTable({ deleteHandler, columnKeys, data, columnKeysTranslated }) {
  const user = useContext(UserContext);
  let searchInput;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columns = columnKeys?.map((item, index) => ({
    key: item,
    title: columnKeysTranslated.map((x) => x)[index],
    dataIndex: ['title', 'category'].includes(item) ? [item, user.language ? user.language.selected.code : ''] : item,
    ...getColumnSearchProps(
      ['title', 'category'].includes(item) ? [item, user.language ? user.language.selected.code : ''] : item,
    ),
  }));

  columns?.push({
    title: 'Postupak',
    render: (record) => (
      <>
        {record.galena && (
          <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ cursor: 'pointer' }}>
              <Link to={`/magistrala/doctor/${record._id}`}>
                <EditOutlined title='Izmeni doktora' />
              </Link>
            </div>
            <div style={{ marginLeft: '.5rem', cursor: 'pointer' }}>
              <Popconfirm
                placement='left'
                title={`Da li zelite da obrisete doktora?`}
                onConfirm={() => deleteHandler(record._id)}
                okText='Ok'
                cancelText='Cancel'
              >
                <DeleteOutlined title='Izbrisi doktora' />
              </Popconfirm>
            </div>
          </div>
        )}
      </>
    ),
  });

  return (
    <div>
      <div className='actions-block'>
        <Link to='/magistrala/doctor/new'>
          <button className='b2b-primary'>Kreiraj doktora</button>
        </Link>
      </div>
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        pagination={{
          defaultPageSize: 20,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
        locale={{
          emptyText: (
            <span>
              <InfoCircleOutlined style={{ fontSize: '1.9rem', marginTop: '1.5rem' }} />
              <p style={{ marginBottom: '1.5rem', marginTop: '0.3rem' }}>Nema doktora</p>
            </span>
          ),
        }}
      />
    </div>
  );
}
