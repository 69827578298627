import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import NavMenu from './NavMenu';
import Routes from '../routes/Routes';
import { Layout, Button } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';

const { Content } = Layout;

const AdminLayout = () => {
  // const user = useContext(UserContext);
  const [isCollapsed, setCollapsed] = useState(false);
  const [collapsedNav, setCollapsedNav] = useState(false);

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <NavMenu isCollapsed={isCollapsed} setCollapsed={setCollapsed} collapsedNav={collapsedNav} />
      <Layout>
        <div>
          <Button type='primary' className='btn-collapse' onClick={() => setCollapsedNav(!collapsedNav)}>
            {React.createElement(collapsedNav ? MenuUnfoldOutlined : MenuFoldOutlined)}
          </Button>
          <Link to='/admin' className='logo-mobile'>
            <div className='logo'>
              {/* <img src='logoW.png' alt='Universal logo' /> */}
              <img src='galena_logo.svg' alt='Universal logo' />
            </div>
          </Link>
        </div>

        <Content className='content-space'>
          <Routes />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
