import React, { useState, useEffect, useContext } from 'react';
import { Card, Form, Input, Divider, Select, notification } from 'antd';
import axios from 'axios';
import { UserContext } from '../../App';
import 'moment/locale/sr';
import { DOCTOR_URL, SERVER_URL } from '../../config';
import 'react-phone-number-input/style.css';

const { Option } = Select;

export default function NewAccount(props) {
  const [form] = Form.useForm();
  const { accountId } = props.match.params;
  const user = useContext(UserContext);

  const [drugs, setDrugs] = useState([]);
  const [account, setAccount] = useState();
  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState();
  const [ordinations, setOrdinations] = useState([]);
  const [ordination, setOrdination] = useState();
  const [doctors, setDoctors] = useState([]);
  const [doctor, setDoctor] = useState();

  const headers = {
    withCredentials: false,
    headers: { Authorization: `Bearer ${user.data.token}` },
  };

  const getAccount = async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/account/${accountId}`, headers);
      setAccount(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (accountId) {
      getAccount();
    }
  }, [accountId]);

  useEffect(() => {
    if (account) {
      form.setFieldsValue({
        ...account,
        drug: account?.drug?._id,
      });
    }
  }, [account]);

  const getDrugs = async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/data`, headers);
      setDrugs(data.items);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getPatients = async () => {
    try {
      const galena = await axios.get(`${SERVER_URL}/patients`, headers);
      // const melo = await axios.get(`${DOCTOR_URL}/melo/patients`, headers);
      setPatients([...galena.data]);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getOrdinations = async () => {
    try {
      const galena = await axios.get(`${SERVER_URL}/ordinations`, headers);
      // const melo = await axios.get(`${DOCTOR_URL}/melo/ordinations`, headers);
      setOrdinations([...galena.data]);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getDoctors = async () => {
    try {
      const galenaDoctors = await axios.get(`${SERVER_URL}/doctors`, headers);
      // const meloDoctors = await axios.get(`${DOCTOR_URL}/melo/doctors`, headers);
      setDoctors([...galenaDoctors.data]);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (user.data.role.includes('superAdmin')) getDoctors();
    getPatients();
    getDrugs();
    getOrdinations();
  }, []);

  const onFinish = async (values) => {
    if (typeof values.quantity === 'string' && values.quantity.includes('.')) {
      notification.info({
        message: 'Unesite ceo broj za količinu',
        placement: 'bottomRight',
      });
      return 0;
    }

    if (doctor) values.doctor = doctor._id;
    if (ordination) values.ordination = ordination.name;
    if (patient) values.patient = patient.name;

    try {
      if (!accountId) {
        const { data } = await axios.post(`${SERVER_URL}/accounts`, values, headers);
        notification.success({
          message: data.message,
          placement: 'bottomRight',
        });
      } else {
        const { data } = await axios.put(`${SERVER_URL}/account/${accountId}`, values, headers);
        notification.success({
          message: data.message,
          placement: 'bottomRight',
        });
      }
      setTimeout(() => (window.location.href = '/magistrala/accounts'), 800);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (ordination) {
      form.setFieldsValue({
        ordinationAddress: ordination.address,
        ordinationPhone: ordination.phone[0],
      });
    }
  }, [ordination]);

  useEffect(() => {
    if (patient) {
      form.setFieldsValue({
        patientAddress: patient.address,
        patientPhone: Array.isArray(patient.phone) ? patient.phone[0] : patient.phone,
        dateOfBirth: patient.dateOfBirth,
      });
    }
  }, [patient]);

  return (
    <div className='dashboard'>
      <div className='card-wrapper' style={{ justifyContent: 'center' }}>
        <Card title={!accountId ? 'Kreiraj radni nalog' : 'Azuriraj radni nalog'} bordered={false}>
          <Form onFinish={(values) => onFinish(values)} layout='horizontal' form={form}>
            <Form.Item
              label='Ordinacija'
              name='ordination'
              rules={[
                {
                  required: true,
                  message: 'Molimo Vas da unesete ordinaciju!',
                },
              ]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.key?.indexOf(input) >= 0
                }
                onSelect={(val) => setOrdination(ordinations.find((ord) => ord._id === val))}
              >
                {ordinations?.map((ord) => (
                  <Option key={ord._id} value={ord._id}>
                    {ord.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label='Pacijent'
              name='patient'
              rules={[
                {
                  required: true,
                  message: 'Molimo Vas da unesete pacijenta!',
                },
              ]}
            >
              <Select
                showSearch
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(val) => setPatient(patients.find((pat) => pat._id === val))}
              >
                {patients?.map((patient) => (
                  <Option key={patient._id} value={patient._id}>
                    {patient.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label='Lek'
              name='drug'
              rules={[
                {
                  required: true,
                  message: 'Molimo Vas da izaberete lek!',
                },
              ]}
            >
              <Select
                showSearch
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {drugs?.map((drug) => (
                  <Option key={drug._id} value={drug._id}>
                    {drug.title.sr}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label='Kolicina'
              name='quantity'
              rules={[
                {
                  required: true,
                  message: 'Molimo Vas da izaberete kolicinu!',
                },
              ]}
            >
              <Input type='number' />
            </Form.Item>

            <Form.Item
              name='date'
              label='Datum propisivanja'
              rules={[
                {
                  required: true,
                  message: 'Molimo Vas da unesete datum propisivanja!',
                },
              ]}
            >
              <Input type='date' style={{ border: 'none', borderBottom: '1px solid #d9d9d9' }} />
            </Form.Item>
            <Form.Item
              name='doctor'
              label='Doktor'
              rules={[
                {
                  required: true,
                  message: 'Molimo Vas da odaberete doktora!',
                },
              ]}
            >
              <Select
                showSearch
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(val) => setDoctor(doctors.find((doc) => doc._id === val))}
              >
                {doctors?.map((doctor) => (
                  <Option key={doctor._id} value={doctor._id}>
                    {doctor.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Divider type='horizontal' />
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <button className='b2b-primary' htmlType='submit'>
                {!accountId ? 'Kreiraj' : 'Azuriraj'} radni nalog
              </button>
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
}
