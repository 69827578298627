import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, allowed, user, ...rest }) => {
  const isAllowed = user?.role.filter((value) => allowed.indexOf(value) !== -1);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAllowed?.length ? ( // if signed in and user's role is allowed
          <Component {...props} /> // render component
        ) : (
          <Redirect to='/login' /> // else redirect
        )
      }
    />
  );
};

export default ProtectedRoute;
