import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu, Select, Badge } from 'antd';
import { ROUTES } from '../../config';
import { io } from 'socket.io-client';
import { UserContext, NotificationContext } from '../../App';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import i18n from '../../i18n';
import { SOCKET_URL } from '../../config';

const { SubMenu } = Menu;
const { Sider } = Layout;

const NavMenu = (props) => {
  // const socket = io(SOCKET_URL, { path: '/socket.io' });
  const { isCollapsed, collapsedNav } = props;
  const currentuser = useContext(UserContext);
  let { sidebarContext, unreadNotification } = useContext(NotificationContext);

  const handleSignOutSubmit = async (event) => {
    event.preventDefault();
    sessionStorage.removeItem('user');
    localStorage.removeItem('orderedProducts');
    localStorage.removeItem('amounts');
    localStorage.removeItem('totalPrice');

    // if (currentuser && currentuser.data) {
    //   socket.emit('disconnected', currentuser.data.id);
    // }

    window.location.href = '/login';
  };

  // useEffect(() => {
  //   socket.emit('connected', currentuser?.data?.id);
  //   socket.on('send-notification', async () => {
  //     await sidebarContext.getNumberOfNotifcations();
  //   });
  // });

  return (
    <Sider
      breakpoint='lg'
      className={collapsedNav ? 'nav-menu-collapsed' : 'nav-display'}
      trigger={null}
      collapsible
      collapsed={isCollapsed}
      style={{ zIndex: 6 }}
    >
      <Link to='/company' className='logo-desktop'>
        <div className='logo'>
          <img src='/galena_logo.svg' style={{ maxHeight: '49px', width: '70%' }} alt='Universal api' />
        </div>
      </Link>
      <Menu theme='dark' mode='inline' defaultSelectedKeys={[window.location.pathname]}>
        {currentuser.data &&
          currentuser.data.role &&
          ROUTES.filter((route) => route.showInMenu.some((item) => currentuser.data.role.indexOf(item) !== -1)).map(
            (route) => (
              <SubMenu
                className='submenu'
                key={route.label.toLowerCase()}
                title={
                  isCollapsed ? (
                    <route.icon />
                  ) : (
                    <span>
                      {route.label}
                      {route.label === 'Notifikacije' && unreadNotification !== 0 && (
                        <Badge
                          size='small'
                          style={{ boxShadow: 'none' }}
                          count={unreadNotification}
                          overflowCount={99}
                          offset={[1, -15]}
                        />
                      )}
                    </span>
                  )
                }
              >
                {route.children
                  .filter((item) => item.showInMenu)
                  .filter((item) => currentuser.data.role.some((role) => item.allowed.indexOf(role) !== -1))
                  .map((item) => (
                    <Menu.Item key={item.path}>
                      <Link to={item.path}>{item.label}</Link>
                    </Menu.Item>
                  ))}
              </SubMenu>
            ),
          )}
      </Menu>
      <div className='sider-profile-controls'>
        <Link
          to={`/magistrala/view-user/${currentuser && currentuser?.data?.id}`}
          className='sider-profile-controls-profile'
        >
          <UserOutlined />
        </Link>
        <LogoutOutlined onClick={handleSignOutSubmit} className='sider-profile-controls-logout' />
        <Select
          getPopupContainer={(trigger) => trigger.parentNode}
          dropdownAlign={{ offset: [0, -105] }}
          className='sider-profile-controls sider-profile-controls-language'
          value={currentuser.language && currentuser.language.selected.name}
          onChange={(lang) => {
            i18n.changeLanguage(lang);
            currentuser.setLanguage({
              ...currentuser.language,
              selected: currentuser.language.list.find((l) => l.name === lang),
            });
          }}
        >
          {currentuser.language &&
            currentuser.language.list.map((lang) => (
              <Select.Option value={lang.name} key={lang.name}>
                {lang.name}
              </Select.Option>
            ))}
        </Select>
      </div>
    </Sider>
  );
};
export default NavMenu;
