import React, { useContext, useEffect, useState } from 'react';
import { Form, Input, InputNumber, notification, Radio, Select, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import { io } from 'socket.io-client';
import { UserContext } from '../../App';
import 'moment/locale/sr';
import { DOCTOR_URL, SERVER_URL, SOCKET_URL } from '../../config';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import raw from './activeSubstances.txt';
import SystematicUndevided from './SystematicUndevided';
import SystematicDevided from './SystematicDevided';
import LocalUndevided from './LocalUndevided';
import LocalDevided from './LocalDevided';
import { useHistory } from 'react-router-dom';

const { Option } = Select;
const { TextArea } = Input;

export default function NewRecepie(props) {
  const history = useHistory();
  // const socket = io(SOCKET_URL, { path: '/socket.io' });

  const [form] = Form.useForm();
  const { recepieId } = props.match.params;
  const user = useContext(UserContext);
  const isMeloDoctor = user.data.role.includes('admin') && !user.data.role.includes('superAdmin');

  const [selectedDrugs, setSelectedDrugs] = useState([]);
  const [doesExists, setDoesExists] = useState(false);
  const [recepie, setRecepie] = useState();
  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState();
  const [ordinations, setOrdinations] = useState([]);
  const [ordination, setOrdination] = useState();
  const [usecase, setUsecase] = useState();
  const [pharmaShape, setPharmaShape] = useState();
  const [activeSubstances, setActiveSubstances] = useState([]);
  const [selectedRecepieType, setSelectedRecepieType] = useState('');
  const [narcoticNum, setNarcoticNum] = useState('');
  const [repetition, setRepetition] = useState('');
  const [type, setType] = useState('galenic');
  const [customRecepie, setCustomRecepie] = useState('');
  const [galenaDrugs, setGalenaDrugs] = useState([]);
  const [recipePatternGroups, setRecipePatternGroups] = useState([]);
  const [recipePatterns, setRecipePatterns] = useState([]);
  const [selectedRecipePatternGroup, setSelectedRecipePatternGroup] = useState();
  const [selectedRecipePattern, setSelectedRecipePattern] = useState();
  const [recipeText, setRecipeText] = useState();
  const [isDropDownVisible, setIsDropDownVisible] = useState(false);

  useEffect(() => {
    fetch(raw)
      .then((r) => r.text())
      .then((text) => {
        const asd = [];
        const removeNums = text.replace(/[    0-9]+. /g, '');
        removeNums.split('\n').forEach((word) => word.length && asd.push(word));
        setActiveSubstances(asd);
      });
  }, []);

  const headers = {
    withCredentials: false,
    headers: { Authorization: `Bearer ${user.data.token}` },
  };

  const getDoesWorkAccountExists = async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/recepie/account/${recepieId}`, headers);
      setDoesExists(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getRecepie = async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/recepie/${recepieId}`, headers);
      setRecepie(data);
      const patterngroups = await axios.get(`${SERVER_URL}/recipe-pattern-groups`, headers);
      setRecipePatternGroups(patterngroups.data);
      if (data.recipePatternGroup) {
        setSelectedRecipePatternGroup(data.recipePatternGroup);
        // console.log(patterngroups);
        for (let i = 0; i < patterngroups.data.length; i++) {
          if (patterngroups.data[i]._id.toString() === data.recipePatternGroup.toString()) {
            // console.log('set');
            setRecipePatterns(patterngroups.data[i].recipePatterns);
            setSelectedRecipePattern(data.recipePattern);
            setRecipeText(data.recipeText);
          }
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // console.log(recipePatterns, 'daa');
  useEffect(() => {
    if (recepieId) {
      getRecepie();
    }
  }, [recepieId]);

  const disabledBtn =
    recepie?.status === 'RECEIVED' && (user.data.role.includes('doctor') || user.data.role.includes('admin'));

  useEffect(() => {
    if (recepie) {
      form.setFieldsValue({ ...recepie });
      setSelectedDrugs(recepie.drugs);
    }
  }, [recepie]);

  // fetch galenic drugs for dropdown when type of recipe is Galenski lek
  const getGalenaProducts = async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/data`, headers);
      const sortByTitle = (a, b) => {
        const titleA = a.title.sr.toLowerCase();
        const titleB = b.title.sr.toLowerCase();

        if (titleA < titleB) {
          return -1;
        } else if (titleA > titleB) {
          return 1;
        } else {
          return 0;
        }
      };

      return data.items.sort(sortByTitle);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getGalenaProducts().then((r) => setGalenaDrugs(r));
    getRecipePatterns();
  }, []);

  const getPatients = async () => {
    let meloLink, galenaLink;
    if (user.data.role.includes('superAdmin')) {
      // meloLink = `${DOCTOR_URL}/melo/patients`;
      galenaLink = `${SERVER_URL}/patients`;
    } else if (user.data.role.includes('doctor')) {
      // meloLink = `${DOCTOR_URL}/melo/doctor-patients/${user.data.id}`;
      galenaLink = `${SERVER_URL}/doctor/patients/${user.data.id}`;
    } else if (isMeloDoctor) {
      // meloLink = `${DOCTOR_URL}/melo/doctor-patients/${user.data.id}`;
    }

    try {
      if (isMeloDoctor) {
        const { data } = await axios.get(galenaLink, headers);
        setPatients(data);
      } else if (user.data.role.includes('doctor')) {
        const { data } = await axios.get(galenaLink, headers);

        setPatients(data);
      } else {
        const galena = await axios.get(galenaLink, headers);
        // const melo = await axios.get(meloLink, headers);
        // console.log(galena, melo);
        setPatients([...galena.data]);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const getRecipePatterns = async () => {
    try {
      const patterngroups = await axios.get(`${SERVER_URL}/recipe-pattern-groups`, headers);
      setRecipePatternGroups(patterngroups.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getOrdinations = async () => {
    let meloLink, galenaLink;
    if (user.data.role.includes('superAdmin')) {
      // meloLink = `${DOCTOR_URL}/melo/ordinations`;
      galenaLink = `${SERVER_URL}/ordinations`;
    } else if (user.data.role.includes('doctor')) {
      // meloLink = `${DOCTOR_URL}/melo/doctor-ordination/${user.data.id}`;
      galenaLink = `${SERVER_URL}/doctor/ordinations/${user.data.id}`;
    } else if (isMeloDoctor) {
      // meloLink = `${DOCTOR_URL}/melo/doctor-ordination/${user.data.id}`;
    }

    try {
      if (isMeloDoctor) {
        // const { data } = await axios.get(meloLink, headers);
        const { data } = await axios.get(galenaLink, headers);

        setOrdinations(data);
      } else if (user.data.role.includes('doctor')) {
        const { data } = await axios.get(galenaLink, headers);

        setOrdinations(data);
      } else {
        const galena = await axios.get(galenaLink, headers);
        // const melo = await axios.get(meloLink, headers);

        setOrdinations([
          ...galena.data,
          //  ...melo.data
        ]);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getPatients();
    if (recepieId && user.data.role.includes('superAdmin')) getDoesWorkAccountExists();
    getOrdinations();
  }, [recepieId]);

  useEffect(() => {
    if (recepie) {
      setUsecase(recepie.usecase);
      setSelectedRecepieType(recepie.recepieType);
      setRepetition(recepie.repetition);
      setNarcoticNum(recepie.narcoticNum);
      setType(recepie.type);
      setPharmaShape(recepie.pharmaShape);
      if (recepie.type === 'custom') setCustomRecepie(recepie.custom);
    }
  }, [recepie]);

  const onFinish = async (values) => {
    if (!values.ordinationName) {
      return notification.warn({
        message: 'Molim, izaberite zdrastvenu ustanovu',
        duration: 2.5,
        placement: 'bottomRight',
      });
    } else if (!values.patientName) {
      return notification.warn({
        message: 'Molim, izaberite pacijenta',
        duration: 2.5,
        placement: 'bottomRight',
      });
    } else if (!values.diagnoseName) {
      return notification.warn({
        message: 'Molim, unesite naziv ili šifru dijagnoze',
        duration: 2.5,
        placement: 'bottomRight',
      });
    } else if (!type) {
      return notification.warn({
        message: 'Molim, izaberite tip recepta',
        duration: 2.5,
        placement: 'bottomRight',
      });
    } else if (!selectedRecepieType) {
      return notification.warn({
        message: 'Molim, izaberite vrstu recepta',
        duration: 2.5,
        placement: 'bottomRight',
      });
    }

    if (type === 'magistral') {
      if (!usecase) {
        return notification.warn({
          message: 'Molim, odaberite farmaceutsku formu. Sistemsko ili lokalno?',
          duration: 2.5,
          placement: 'bottomRight',
        });
      } else if (!pharmaShape) {
        return notification.warn({
          message: 'Molim, odaberite farmaceutski oblik. Podeljen ili nepodeljen?',
          duration: 2.5,
          placement: 'bottomRight',
        });
      }
    } else if (type === 'custom') {
      if (!customRecepie) {
        return notification.warn({
          message: 'Molim, unesite recept.',
          duration: 2.5,
          placement: 'bottomRight',
        });
      }
    } else if (type === 'galenic') {
      if (!values.galenaDrug || !values.galenaDrugQuantity) {
        return notification.warn({
          message: 'Molim, unesite recept i količinu.',
          duration: 2.5,
          placement: 'bottomRight',
        });
      }
    } else if (type === 'pattern') {
      values.recipePattern = selectedRecipePattern;
      values.recipePatternGroup = selectedRecipePatternGroup;
      values.recipeText = recipeText;
    }

    if (selectedRecepieType === 'unique') {
      if (!narcoticNum) {
        return notification.warn({
          message: 'Molim, upišite redni broj iz knjige izdatih recepata.',
          duration: 2.5,
          placement: 'bottomRight',
        });
      }
    } else if (selectedRecepieType === 'renewable') {
      if (!repetition) {
        return notification.warn({
          message: 'Molim, upišite broj ponavljanja.',
          duration: 2.5,
          placement: 'bottomRight',
        });
      }
    }

    values.type = type;
    values.custom = customRecepie;
    values.narcoticNum = narcoticNum;
    values.repetition = repetition;
    values.ordinationName = ordination && ordination.name;
    values.patientName = patient && patient.name;

    let description;
    switch (values.status) {
      case 'RECEIVED':
        description = 'Recept je zaprimljen i nije ga moguce vise menjati.';
        break;
      case 'IN_PRODUCTION':
        description = 'Recept je u izradi.';
        break;
      case 'PRODUCED':
        description = 'Recept je izradjen.';
        break;
      default:
        break;
    }

    values.drugs = selectedDrugs;

    try {
      if (!recepieId) {
        const { data } = await axios.post(`${SERVER_URL}/recepies`, values, headers);
        notification.success({
          message: data.message,
          placement: 'bottomRight',
        });
      } else {
        const { data } = await axios.put(`${SERVER_URL}/recepie/${recepieId}`, values, headers);
        notification.success({
          message: data.message,
          placement: 'bottomRight',
        });

        if (user.data.role.includes('superAdmin')) {
          // socket.emit('recepie-update', {
          //   doctor: data.recepie.doctorID,
          //   recepie: recepieId,
          //   ordination: values.ordinationName || recepie.ordinationName,
          //   description,
          // });
        }
      }
      setTimeout(() => history.push('/magistrala/recepies'), 800);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (ordination) {
      form.setFieldsValue({
        ordinationAddress: ordination.address,
        ordinationPhone: ordination.phone[0],
      });
    }
  }, [ordination]);

  useEffect(() => {
    if (patient) {
      form.setFieldsValue({
        patientAddress: patient.address,
        patientPhone: Array.isArray(patient.phone) ? patient.phone[0] : patient.phone,
        dateOfBirth: patient.dateOfBirth ? new Date(patient.dateOfBirth).toLocaleDateString() : '',
      });
    }
  }, [patient]);

  const createWorkAccount = async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/recepie/work/${recepieId}`, headers);
      notification.success({
        message: data.message,
        placement: 'bottomRight',
      });
      setTimeout(() => window.location.reload(), 800);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleChangeRecipePatternGroup = (e) => {
    setSelectedRecipePatternGroup(e);
    setSelectedRecipePattern(null);
    for (let i = 0; i < recipePatternGroups.length; i++) {
      if (recipePatternGroups[i]._id.toString() === e.toString()) {
        setRecipePatterns(recipePatternGroups[i].recipePatterns);
      }
    }
  };

  const handleChangeRecipePattern = (e) => {
    setSelectedRecipePattern(e);

    for (let i = 0; i < recipePatterns.length; i++) {
      if (recipePatterns[i]._id.toString() === e.toString()) {
        setRecipeText(recipePatterns[i].description);
      }
    }
    // setRecipeText();
  };

  const handleShowDropDown = (e) => {
    console.log(e);

    if (e === 'PRODUCED') {
      setIsDropDownVisible(true);
    } else {
      setIsDropDownVisible(false);
    }
  };

  return (
    <div className='dashboard'>
      <div className='card-wrapper' style={{ justifyContent: 'center' }}>
        <Form onFinish={(values) => onFinish(values)} layout='horizontal' form={form}>
          <div
            style={{
              width: '35rem',
              minWidth: '12rem',
              borderRadius: '1rem',
              border: '1px solid #A0BFDD',
              outline: '2px solid #A0BFDD',
              outlineOffset: '2px',
            }}
          >
            <h2
              style={{
                textTransform: 'uppercase',
                padding: '.5rem 1rem',
                background: '#E1E8F3',
                color: '#787F8A',
                borderTopLeftRadius: '1rem',
                borderTopRightRadius: '1rem',
                borderBottom: '2px solid #A0BFDD',
                margin: 0,
              }}
            >
              <span style={{ color: 'crimson' }}>*</span> zdrastvena ustanova/privatna praksa
            </h2>
            <Form.Item
              style={{
                background: 'white',
                margin: 0,
                padding: '0.2rem 1rem',
                borderBottom: '2px solid #A0BFDD',
              }}
              label='Naziv'
              name='ordinationName'
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.key?.indexOf(input) >= 0
                }
                onSelect={(val) => setOrdination(ordinations.find((ord) => ord._id === val))}
                bordered={false}
              >
                {ordinations?.map((ord) => (
                  <Option key={ord._id} value={ord._id}>
                    {ord.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name='ordinationAddress'
              label='Adresa'
              style={{ background: 'white', margin: 0, padding: '0.2rem 1rem', borderBottom: '2px solid #A0BFDD' }}
            >
              <Input readOnly bordered={false} />
            </Form.Item>
            <Form.Item
              name='ordinationPhone'
              label='Telefon'
              style={{ background: 'white', margin: 0, padding: '0.2rem 1rem', borderBottom: '2px solid #A0BFDD' }}
            >
              <PhoneInput
                style={{ border: 'none' }}
                international
                defaultCountry='RS'
                countryCallingCodeEditable={false}
                readOnly
              />
            </Form.Item>

            <h2
              style={{
                textTransform: 'uppercase',
                padding: '.3rem 1rem',
                background: '#E1E8F3',
                color: '#787F8A',
                margin: 0,
                borderBottom: '2px solid #A0BFDD',
              }}
            >
              <span style={{ color: 'crimson' }}>*</span> pacijent
            </h2>
            <Form.Item
              label='Ime i prezime'
              name='patientName'
              style={{
                background: 'white',
                margin: 0,
                padding: '0.2rem 1rem',
                borderBottom: '2px solid #A0BFDD',
              }}
            >
              <Select
                bordered={false}
                showSearch
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(val) => setPatient(patients.find((pat) => pat._id === val))}
              >
                {patients &&
                  patients.length > 0 &&
                  patients.map((patient) => (
                    <Option key={patient._id} value={patient._id}>
                      {patient.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              name='patientPhone'
              label='Broj telefona'
              style={{ background: 'white', margin: 0, padding: '0.2rem 1rem', borderBottom: '2px solid #A0BFDD' }}
            >
              <PhoneInput
                style={{ border: 'none' }}
                international
                defaultCountry='RS'
                countryCallingCodeEditable={false}
                readOnly
              />
            </Form.Item>
            <Form.Item
              label='Datum rodjenja'
              name='dateOfBirth'
              style={{ background: 'white', margin: 0, padding: '0.2rem 1rem', borderBottom: '2px solid #A0BFDD' }}
            >
              <Input readOnly bordered={false} />
            </Form.Item>

            <h2
              style={{
                textTransform: 'uppercase',
                padding: '.3rem 1rem',
                background: '#E1E8F3',
                color: '#787F8A',
                borderBottom: '2px solid #A0BFDD',
                margin: 0,
              }}
            >
              <span style={{ color: 'crimson' }}>*</span> naziv ili šifra dijagnoze
            </h2>
            <Form.Item
              name='diagnoseName'
              style={{
                background: 'white',
                margin: 0,
                padding: '0.2rem 1rem',
                borderBottom: '2px solid #A0BFDD',
              }}
            >
              <Input type='text' bordered={false} />
            </Form.Item>

            <h2
              style={{
                textTransform: 'uppercase',
                padding: '.3rem 1rem',
                background: '#E1E8F3',
                color: '#787F8A',
                borderBottom: '2px solid #A0BFDD',
                margin: 0,
              }}
            >
              <span style={{ color: 'crimson' }}>*</span> tip recepta
            </h2>
            <Form.Item name='type' style={{ margin: 0 }}>
              <Radio.Group
                onChange={(e) => setType(e.target.value)}
                style={{ display: 'flex', flexDirection: 'column' }}
                defaultValue='galenic'
              >
                <Radio
                  value='custom'
                  style={{
                    padding: '0.2rem 0.8rem',
                    borderBottom: '2px solid #A0BFDD',
                    background: 'white',
                  }}
                >
                  <span style={{ marginLeft: '0.6rem' }}>
                    Blanko forma
                    <Tooltip
                      color={'black'}
                      title='Lekar može da upisuje šta god želi, bilo koji gotov lek koji ide na recept.'
                    >
                      <InfoCircleOutlined style={{ marginLeft: '0.3rem', fontSize: '.8rem' }} />
                    </Tooltip>
                  </span>
                </Radio>
                <Radio
                  value='magistral'
                  style={{ padding: '0.2rem 0.8rem', borderBottom: '2px solid #A0BFDD', background: 'white' }}
                >
                  <span style={{ marginLeft: '0.6rem' }}>
                    Magistralni lek
                    <Tooltip
                      color={'black'}
                      title='Odabirom jednog od navedenih polja otvoriće Vam se ispravna forma recepta za željeni farmaceutski oblik, u zavisnosti od toga da li želite sistemsko ili lokalno delovanje magistralnog leka/preparata i da li je farmaceutski oblik podeljen ili nepodeljen.'
                    >
                      <InfoCircleOutlined style={{ marginLeft: '0.3rem', fontSize: '.8rem' }} />
                    </Tooltip>
                  </span>
                </Radio>
                <Radio
                  value='galenic'
                  style={{ padding: '0.2rem 0.8rem', borderBottom: '2px solid #A0BFDD', background: 'white' }}
                >
                  <span style={{ marginLeft: '0.6rem' }}>
                    Galenski lek
                    <Tooltip
                      color={'black'}
                      title='Odabirom jednog od navedenih polja otvoriće Vam se ispravna forma recepta za željeni farmaceutski oblik, u zavisnosti od toga da li želite sistemsko ili lokalno delovanje magistralnog leka/preparata i da li je farmaceutski oblik podeljen ili nepodeljen.'
                    >
                      <InfoCircleOutlined style={{ marginLeft: '0.3rem', fontSize: '.8rem' }} />
                    </Tooltip>
                  </span>
                </Radio>
                <Radio
                  value='pattern'
                  style={{ padding: '0.2rem 0.8rem', borderBottom: '2px solid #A0BFDD', background: 'white' }}
                >
                  <span style={{ marginLeft: '0.6rem' }}>
                    Obrazac leka
                    <Tooltip
                      color={'black'}
                      title='Lekar može da odabere određenu grupu obrazaca i konkretan obrazac leka nakon čega će se ispisati recept.'
                    >
                      <InfoCircleOutlined style={{ marginLeft: '0.3rem', fontSize: '.8rem' }} />
                    </Tooltip>
                  </span>
                </Radio>
              </Radio.Group>
            </Form.Item>

            {type === 'magistral' && (
              <>
                <h2
                  style={{
                    textTransform: 'uppercase',
                    padding: '.3rem 1rem',
                    background: '#E1E8F3',
                    color: '#787F8A',
                    borderBottom: '2px solid #A0BFDD',
                    margin: 0,
                  }}
                >
                  <span style={{ color: 'crimson' }}>*</span> da li navedeni magistralni lek/preparat ima sistemsko ili
                  lokalno delovanje?
                </h2>
                <Form.Item name='usecase' style={{ margin: 0 }}>
                  <Radio.Group
                    onChange={(e) => setUsecase(e.target.value)}
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <Radio
                      value='systematic'
                      style={{
                        padding: '0.2rem 0.8rem',
                        borderBottom: '2px solid #A0BFDD',
                        background: 'white',
                      }}
                    >
                      <span style={{ marginLeft: '0.6rem' }}>Sistemsko</span>
                    </Radio>
                    <Radio
                      value='local'
                      style={{ padding: '0.2rem 0.8rem', borderBottom: '2px solid #A0BFDD', background: 'white' }}
                    >
                      <span style={{ marginLeft: '0.6rem' }}>Lokalno</span>
                    </Radio>
                  </Radio.Group>
                </Form.Item>

                <h2
                  style={{
                    textTransform: 'uppercase',
                    padding: '.3rem 1rem',
                    background: '#E1E8F3',
                    color: '#787F8A',
                    borderBottom: '2px solid #A0BFDD',
                    margin: 0,
                  }}
                >
                  <span style={{ color: 'crimson' }}>*</span> da li je navedeni galenski lek/preparat podeljen ili
                  nepodeljen?
                </h2>
                <Form.Item name='pharmaShape' style={{ margin: 0 }}>
                  <Radio.Group
                    onChange={(e) => setPharmaShape(e.target.value)}
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <Radio
                      value='devided'
                      style={{
                        padding: '0.2rem 0.8rem',
                        borderBottom: '2px solid #A0BFDD',
                        background: 'white',
                      }}
                    >
                      <span style={{ marginLeft: '0.6rem' }}>Podeljen</span>
                    </Radio>
                    <Radio
                      value='undevided'
                      style={{ padding: '0.2rem 0.8rem', borderBottom: '2px solid #A0BFDD', background: 'white' }}
                    >
                      <span style={{ marginLeft: '0.6rem' }}>Nepodeljen</span>
                    </Radio>
                  </Radio.Group>
                </Form.Item>

                {usecase === 'systematic' ? (
                  pharmaShape === 'undevided' ? (
                    <SystematicUndevided
                      selectedDrugs={selectedDrugs}
                      setSelectedDrugs={setSelectedDrugs}
                      activeSubstances={activeSubstances}
                    />
                  ) : pharmaShape === 'devided' ? (
                    <SystematicDevided
                      selectedDrugs={selectedDrugs}
                      setSelectedDrugs={setSelectedDrugs}
                      activeSubstances={activeSubstances}
                    />
                  ) : null
                ) : pharmaShape === 'undevided' ? (
                  <LocalUndevided
                    selectedDrugs={selectedDrugs}
                    setSelectedDrugs={setSelectedDrugs}
                    activeSubstances={activeSubstances}
                  />
                ) : pharmaShape === 'devided' ? (
                  <LocalDevided
                    selectedDrugs={selectedDrugs}
                    setSelectedDrugs={setSelectedDrugs}
                    activeSubstances={activeSubstances}
                  />
                ) : null}
              </>
            )}
            {type === 'custom' && (
              <>
                <h2
                  style={{
                    margin: 0,
                    padding: '0.2rem .6rem',
                    fontSize: '1.5rem',
                  }}
                >
                  <span style={{ color: 'crimson' }}>*</span> RP/
                </h2>
                <Form.Item style={{ margin: 0 }}>
                  <TextArea
                    rows={7}
                    bordered={false}
                    onChange={(e) => setCustomRecepie(e.target.value)}
                    defaultValue={customRecepie}
                    style={{ outline: 'none', border: 'none' }}
                  />
                </Form.Item>
              </>
            )}
            {type === 'galenic' && (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '0.2rem .6rem',
                  }}
                >
                  <h2
                    style={{
                      margin: 0,
                      fontSize: '1.5rem',
                    }}
                  >
                    <span style={{ color: 'crimson' }}>*</span> RP/
                  </h2>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      height: '200px',
                      width: '100%',
                      padding: '0.2rem .6rem',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Form.Item style={{ margin: 0 }} name='galenaDrug'>
                      <Select
                        showSearch
                        placeholder='Odaberite galenski lek'
                        optionFilterProp='children'
                        style={{ width: '300px' }}
                      >
                        {galenaDrugs?.map((val) => (
                          <Select.Option value={val._id} key={val._id}>
                            {val.title['sr']}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'baseline',
                      }}
                    >
                      <Form.Item name='galenaDrugQuantity'>
                        <InputNumber min={1} defaultValue={0} />
                      </Form.Item>
                      <div style={{ paddingLeft: '10px' }}>komada</div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {type === 'pattern' && (
              <>
                <h2
                  style={{
                    margin: 0,
                    padding: '0.2rem .6rem',
                    fontSize: '1.5rem',
                  }}
                >
                  <span style={{ color: 'crimson' }}>*</span> RP/
                </h2>

                <Form.Item
                  label='Grupa:'
                  style={{
                    background: 'white',
                    margin: 0,
                    padding: '0.2rem 1rem',
                    borderBottom: '2px solid #A0BFDD',
                  }}
                >
                  <Select
                    bordered={false}
                    onChange={(e) => {
                      handleChangeRecipePatternGroup(e);
                    }}
                    value={selectedRecipePatternGroup}
                  >
                    {recipePatternGroups &&
                      recipePatternGroups.length > 0 &&
                      recipePatternGroups.map((item, index) => {
                        return <Option value={item._id}>{item.name}</Option>;
                      })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label='Obrazac:'
                  style={{
                    background: 'white',
                    margin: 0,
                    padding: '0.2rem 1rem',
                    borderBottom: '2px solid #A0BFDD',
                  }}
                >
                  <Select
                    bordered={false}
                    onChange={(e) => {
                      handleChangeRecipePattern(e);
                    }}
                    disabled={selectedRecipePatternGroup ? false : true}
                    value={selectedRecipePattern}
                  >
                    {recipePatterns &&
                      recipePatterns.length > 0 &&
                      recipePatterns.map((item, index) => {
                        return <Option value={item._id}>{item.name}</Option>;
                      })}
                  </Select>
                </Form.Item>
                <Form.Item style={{ margin: 0 }}>
                  <TextArea
                    rows={7}
                    bordered={false}
                    // disabled={true}
                    value={recipeText}
                    onChange={(e) => {
                      setRecipeText(e.target.value);
                    }}
                    style={{ outline: 'none', border: 'none', color: 'black' }}
                  />
                </Form.Item>
              </>
            )}

            <h2
              style={{
                textTransform: 'uppercase',
                padding: '.3rem 1rem',
                background: '#E1E8F3',
                color: '#787F8A',
                borderBottom: '2px solid #A0BFDD',
                margin: 0,
              }}
            >
              <span style={{ color: 'crimson' }}>*</span> Vrsta recepta (označiti)
            </h2>
            <Form.Item name='recepieType' style={{ margin: 0 }}>
              <Radio.Group
                style={{
                  borderBottom: '2px solid #A0BFDD',
                  background: 'white',
                  display: 'flex',
                  flexDirection: 'column',
                }}
                onChange={(e) => setSelectedRecepieType(e.target.value)}
              >
                <Radio
                  value='non-renewable'
                  style={{
                    padding: '0.2rem 0.8rem',
                    borderBottom: '2px solid #A0BFDD',
                    background: 'white',
                    margin: 0,
                  }}
                >
                  Neobnovljiv
                </Radio>
                <Radio
                  value='renewable'
                  style={{
                    padding: '0.2rem 0.8rem',
                    borderBottom: '2px solid #A0BFDD',
                    background: 'white',
                    margin: 0,
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    Obnovljiv
                    {selectedRecepieType === 'renewable' && (
                      <Input
                        style={{ marginLeft: '2rem', height: '1.4rem' }}
                        bordered={false}
                        type='number'
                        placeholder='upisati broj ponavljanja'
                        onChange={(e) => setRepetition(e.target.value)}
                        defaultValue={repetition}
                      />
                    )}
                  </div>
                </Radio>
                <Radio
                  value='unique'
                  style={{
                    padding: '0.2rem 0.8rem',
                    borderBottom: '2px solid #A0BFDD',
                    background: 'white',
                    margin: 0,
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    Poseban
                    {selectedRecepieType === 'unique' && (
                      <Input
                        style={{ marginLeft: '2rem', width: '20rem', height: '1.4rem' }}
                        bordered={false}
                        type='number'
                        placeholder='upisati redni br. iz knjige izdatih narkotika'
                        onChange={(e) => setNarcoticNum(e.target.value)}
                        defaultValue={narcoticNum}
                      />
                    )}
                  </div>
                </Radio>
                <Radio
                  value='limited'
                  style={{
                    padding: '0.2rem 0.8rem',
                    background: 'white',
                    margin: 0,
                  }}
                >
                  Ograničen
                </Radio>
              </Radio.Group>
            </Form.Item>

            <h2 style={{ padding: '.2rem .8rem', margin: 0 }}>Napomena:</h2>
            <Form.Item name='prescriptionNote' style={{ margin: 0 }}>
              <TextArea rows={3} bordered={false} style={{ outline: 'none', border: 'none' }} />
            </Form.Item>

            <Form.Item
              name='drugID'
              label='Broj potvrde'
              style={{
                padding: '0.2rem .8rem',
                background: 'white',
                margin: 0,
                borderBottom: '2px solid #A0BFDD',
                borderTop: '2px solid #A0BFDD',
              }}
            >
              <Input bordered={false} />
            </Form.Item>
            <Form.Item
              style={{ padding: '0.2rem .8rem', background: 'white', margin: 0, borderBottom: '2px solid #A0BFDD' }}
              name='prescriptionDate'
              label='Datum propisivanja leka'
            >
              <Input type='date' bordered={false} />
            </Form.Item>

            <Form.Item
              name='doctorSignature'
              label='Potpis i faksimil lekara'
              style={{ padding: '0.2rem .8rem', background: 'white', margin: 0, borderBottom: '2px solid #A0BFDD' }}
            >
              <Input bordered={false} />
            </Form.Item>

            <Form.Item
              name='doctorEmail'
              label='Email lekara'
              style={{ padding: '0.2rem .8rem', background: 'white', margin: 0, borderBottom: '2px solid #A0BFDD' }}
              rules={[
                {
                  required: true,
                  message: 'Molim, unesite email lekara',
                },
              ]}
            >
              <Input bordered={false} type='email' />
            </Form.Item>

            <Form.Item
              style={{ padding: '0.2rem .8rem', background: 'white', margin: 0, borderBottom: '2px solid #A0BFDD' }}
              name='issuanceDate'
              label='Datum izdavanja leka'
            >
              <Input type='date' bordered={false} />
            </Form.Item>

            <Form.Item
              name='pharmSignature'
              label='Potpis i faksimil farmaceuta'
              style={{ padding: '0.2rem .8rem', background: 'white', margin: 0, borderBottom: '2px solid #A0BFDD' }}
            >
              <Input bordered={false} />
            </Form.Item>

            {user.data.role.includes('superAdmin') && recepieId && (
              <Form.Item
                name='status'
                label='Status'
                style={{ padding: '0.2rem .8rem', background: 'white', margin: 0, borderBottom: '2px solid #A0BFDD' }}
              >
                <Select
                  bordered={false}
                  showSearch
                  onChange={(e) => {
                    handleShowDropDown(e);
                  }}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  <Option key='1' value='CREATED'>
                    Kreiran
                  </Option>
                  <Option key='2' value='RECEIVED'>
                    Zaprimljen
                  </Option>
                  {/* <Option key='3' value='IN_PRODUCTION'>
                    U izradi
                  </Option> */}
                  <Option key='4' value='PRODUCED'>
                    Izradjen
                  </Option>
                </Select>
              </Form.Item>
            )}
            {isDropDownVisible && (
              <Form.Item
                name='addressForSMS'
                label='Adresa:'
                style={{ padding: '0.2rem .8rem', background: 'white', margin: 0, borderBottom: '2px solid #A0BFDD' }}
              >
                <Select bordered={false} showSearch>
                  {/* <Option key='1' value='Ustanička 63, Beograd'>
                    Ustanička 63, Beograd
                  </Option> */}
                  <Option key='5' value='Južni Bulevar 5, Beograd'>
                    Južni Bulevar 5, Beograd
                  </Option>
                  <Option key='2' value='Nebojšina 24, Beograd'>
                    Nebojšina 24, Beograd
                  </Option>
                  <Option key='3' value='Južni bulevar 38, Beograd'>
                    Južni bulevar 38, Beograd
                  </Option>
                  <Option key='4' value='Doža Đerđa 2, Novi Sad'>
                    Doža Đerđa 2, Novi Sad
                  </Option>
                </Select>
              </Form.Item>
            )}

            <div style={{ display: 'flex', justifyContent: 'end', margin: '1.5rem 1rem' }}>
              {user.data.role.includes('superAdmin') && !doesExists && recepieId && (
                <span
                  className='b2b-primary'
                  onClick={createWorkAccount}
                  style={{ marginRight: '1rem', display: 'flex', alignItems: 'center' }}
                >
                  Kreiraj radni nalog
                </span>
              )}
              <button className='b2b-primary' htmlType='submit' disabled={disabledBtn}>
                {!recepieId ? 'Kreiraj' : 'Ažuriraj'} recept
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}
